import React, { useState, ReactElement } from 'react'
import { BeginScenario, ProgressState } from '../common/types'
import { Questionnaire, State } from '../common/model'
import Button from './components/button'

export const QuestionBox = (
  {descriptionA, descriptionB, progress, nextLabel, labelColor}:
  {descriptionA: string, descriptionB: string, progress: ProgressState, nextLabel: string, labelColor: string}
): ReactElement => {
  const [answer, setAnswer] = useState("none-selected")

  const message = (answer: string) => {
    return {
      'a': 'Only prioritise A',
      'b': 'Prioritise A over B',
      'c': 'Both have equal priority',
      'd': 'Prioritise B over A',
      'e': 'Only prioritise B',
      'none-selected': 'Select an answer'
    }[answer];
  };

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setAnswer(e.currentTarget.value)
  }

  return (
    <div className="fast-fade flex flex-row border-1 rounded shadow p-4 mt-4 bg-white items-base h-56">
      <div className="flex flex-col border-2 border-gray-100 shadow-inner rounded p-5">
        <div className="flex flex-row justify-center pb-2">
          <div className="inline-flex w-8 bg-indigo-600 text-indigo-100 rounded-full font-bold p-1 justify-center shadow">A</div>
        </div>
        <div className="text-sm w-64 mt-2">{descriptionA}</div>
      </div>
      <div className="inline-flex flex-col w-full pl-8 pr-10 justify-center items-center">
        <div id='answer' className="w-64 inline-flex justify-between">
          <input style={{transform: 'scale(1.5)'}} name="question" value="a" type="radio" onChange={onChange} checked={answer === 'a'}/>
          <input style={{transform: 'scale(1.5)'}} name="question" value="b" type="radio" onChange={onChange} checked={answer === 'b'}/>
          <input style={{transform: 'scale(1.5)'}} name="question" value="c" type="radio" onChange={onChange} checked={answer === 'c'}/>
          <input style={{transform: 'scale(1.5)'}} name="question" value="d" type="radio" onChange={onChange} checked={answer === 'd'}/>
          <input style={{transform: 'scale(1.5)'}} name="question" value="e" type="radio" onChange={onChange} checked={answer === 'e'}/>
        </div>
        <div className="italic mt-4">&nbsp;{message(answer)}</div>
        <Button label={nextLabel} className={"mt-2"} color={labelColor} onClick={() => progress()(answer, setAnswer)} disabled={answer === 'none-selected'}/>
      </div>
      <div className="flex flex-col border-2 border-gray-100 shadow-inner rounded p-5">
        <div className="flex flex-row justify-center pb-2">
          <div className="inline-flex w-8 bg-indigo-600 text-indigo-100 rounded-full font-bold p-1 justify-center shadow">B</div>
        </div>
        <div className="text-sm w-64 mt-2">{descriptionB}</div>
      </div>
    </div>
  )
}

const ScenarioView = ({questionnaire, state, beginScenario, progress}: {questionnaire: Questionnaire, state: State, beginScenario: BeginScenario, progress: ProgressState}): ReactElement => {
  const scenario = questionnaire.scenarios[state.scenario-1]
  const question = scenario.questions[state.question-1]

  let nextLabel = 'Next Question'
  let labelColor = 'indigo'
  if (scenario.questions.length === state.question) {
    nextLabel = 'Next Scenario'
    labelColor = 'green'
    if (questionnaire.scenarios.length === state.scenario) {
      nextLabel = 'Complete Assessment'
      labelColor = 'green'
    }
  }

  let questionArea = <div className="inline-flex flex-col">
    When you are ready & have read the scenario description above, click below to start answering questions for this scenario.
    <div className="inline-flex mt-6 flex-col w-48">
      <Button label="Start Scenario" color="indigo" onClick={beginScenario}/>
    </div>
  </div>
    if (state.startedScenario) {
      questionArea = <>
        <div className="italic text-gray-500 text-sm">Question {state.question} of {scenario.questions.length}</div>
        <QuestionBox key={scenario.displayName+'-'+state.question} labelColor={labelColor} descriptionA={question.descriptionA} descriptionB={question.descriptionB} progress={progress} nextLabel={nextLabel}/>
      </>
    }

  return (
    <div key={scenario.displayName} className="fade">
      <div className="text-4xl mt-8">{scenario.displayName}</div>
      <div className="flex lg:flex-row flex-col">
        <img className="inline-block my-8 rounded shadow h-64 w-96" src={"/images/"+scenario.name+".jpg"} />
        <div className="ml-4 my-8" style={{width: "35em"}}>{scenario.description}</div>
      </div>
      {questionArea}
    </div>
  )
}

export default ScenarioView
