import Axios from 'axios'
import API, { User, UserLoginParameters, UserCreateParameters } from '../common/api'
import { Answer } from '../common/model'

const Login = (input: UserLoginParameters): Promise<{granted: boolean, user: User}> => {
  return Axios.post(
    API.user.login, input
  ).then(r => {
    return {granted: r.data.granted, user: r.data.user}
  }).catch(err => {
    console.log(err)
    return {granted: false, user: {id: -1, email: '', name: '', apiKey: ''}}
  })
}

const Create = (input: UserCreateParameters): Promise<{created: boolean, message: string, user: User}> => {
  return Axios.post(
    API.user.create, input
  ).then(r => {
    return {created: r.data.created, message: r.data.message, user: r.data.user}
  }).catch(err => {
    console.log(err)
    return {created: false, message: 'server error, please refresh and try again', user: {id: -1, email: '', name: '', apiKey: ''}}
  })
}

const SaveResults = (userID: number, apiKey: string, answers: string[]): Promise<boolean> => {
  return Axios.post<boolean>(
    API.results.store + '?key=' + btoa(apiKey), {userID, answers}
  ).then(r => {
    return r.data
  }).catch(err => {
    console.log(err)
    return false
  })
}

const GetResults = (userID: number, apiKey: string): Promise<Answer[]> => {
  return Axios.get<Answer[]>(
    API.results.find + '/' + userID + '?key=' + btoa(apiKey)
  ).then(r => {
    return r.data
  }).catch(err => {
    console.log(err)
    return []
  })
}

const DownloadPDF = (userName: string, answers: number[]): Promise<string> => {
  // what do we return? pdf data?
  return Axios.get<string>(
    API.generatePDF + '?userName=' + btoa(userName) + '&answers=' + btoa(JSON.stringify(answers))
  ).then(r => {
    r.data
  }).catch(err => {
    console.log(err)
    return 'error'
  })
}

const Server = {
  login: Login,
  create: Create,
  saveResults: SaveResults,
  getResults: GetResults,
  downloadPDF: DownloadPDF
}

export default Server
