import React, { ReactElement, useState } from 'react'
import { Mark, CalculateMaxScore, MarkAsPercentile } from './marking'
import { Chart } from './chart'
import { attributes } from '../common/model'
import reportContent from './reportContent'
import Server from './server'

const textByThreshold = (shorthand: string, score: number): ReactElement => {
  if (score >= 75) {
    return reportContent[shorthand].good
  } else if (score >= 50) {
    return reportContent[shorthand].bad
  } else {
    return reportContent[shorthand].ugly
  }
}

// colorByThreshold returns a value to colour the report chart
// TODO this is a copy, DRY it out
const colorByThreshold = (score: number) => {
  if (score >= 85) {
    return '#00E800'
  } else if (score >= 70) {
    return '#86FF00'
  } else if (score >= 55) {
    return '#FFFF00'
  } else if (score >= 40) {
    return '#FFB600'
  } else if (score >= 25) {
    return '#FF1700'
  } else {
    return '#D20000'
  }
}

// A dropdown section, when minimized showing score and expanding to tell the
// user more about their score for the specific attribute
// good, bad & ugly are descriptions to use depending on how well the user did.
export const ResultDescription = ({shorthand, title, score, pdf}) => {
  var [expanded, setExpanded] = useState(false)

  const opening = reportContent[shorthand].opening
  const preamble = reportContent[shorthand].preamble
  const quote = reportContent[shorthand].quote
  const quoteCredit = reportContent[shorthand].quoteCredit
  const resultDescription = textByThreshold(shorthand, score)

  const pdfNewCleanPage = {
    height: '1130px',
    background: 'url(/images/pdf-clean-page.png)',
    backgroundSize: 'cover',
    pageBreakBefore: 'always'
  }
  const pdfNewQuotePage = {
    height: '1130px',
    background: 'url(/images/pdf-quote-page.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    pageBreakBefore: 'always'
  }
  const quoteStyling = "relative top-48 pl-80 text-white text-2xl font-semibold"

  const expandedContent =
    <div key={shorthand} className="fade mt-4 text-gray-600">
      <div>{opening}</div>
      <div className="mt-4 italic font-semibold">
        {"“" + quote + "”"}
        <p className="mt-2 font-semibold">{quoteCredit}</p>
      </div>
      <div className="mt-4">{preamble}</div>
      <div className="mt-6"><b>Your {title} result</b></div>
      <div>{resultDescription}</div>
    </div>

  const pdfContent =
    <>
      <div className="pl-16" style={pdfNewQuotePage}>
        <div className="pt-16 flex flex-row flex-start items-center">
          <div className="text-blue-900 font-semibold">{title}</div>
          <div style={{backgroundColor: colorByThreshold(score)}} className="ml-2 inline-flex p-2 rounded shadow">{score}</div>
        </div>
        <div className={quoteStyling}>
          <div className="relative" style={{width: "10em", margin: 'auto', height: "10em"}}>
            {quote}
          </div>
          <div className="text-sm text-gray-100 italic pl-20 pt-10" style={{margin: 'auto'}}>{quoteCredit}</div>
        </div>
        <div className="-mt-40 text-xs w-5/12">
          <div>{opening}</div>
          <div>{preamble}</div>
        </div>
      </div>
      <div className="pl-16" style={pdfNewCleanPage}>
        <div className="pt-20 text-blue-900"><b>Your {title} result</b></div>
        <div className="text-sm w-2/3">{resultDescription}</div>
      </div>
    </>

  const webContent = <div className="flex flex-col bg-gray-100 w-t rounded shadow p-4 mt-4 select-none">
    <div className="inline-flex align-center justify-between" onClick={() => (setExpanded(!expanded))}>
      <div>
        <div style={{backgroundColor: colorByThreshold(score)}} className="inline-flex p-2 rounded">{score}</div>
        <div className="ml-4 inline-flex uppercase text-gray-600 text-semibold">{shorthand}</div>
        <div className="ml-2 inline-flex">{title}</div>
      </div>
      <div className="p-2 rounded select-none inline-flex items-center hover:bg-gray-200 uppercase text-gray-500 text-xs">{expanded ? 'Show Less' : 'Show More' }</div>
    </div>
    {expanded ? expandedContent : <></>}
  </div>

  return (
    <>
      { pdf ? pdfContent : webContent }
    </>
  )
}

export const Understanding = ({pdf}: {pdf: boolean}): ReactElement => {
  let blockStyle = "w-2/3"
  let leading = "leading-6"
  let paragraphWidth = "" // intended for PDF only
  let paragraphStartGap = "mt-4" // the room between title/sub & paragraph in quote pages
  let pdfNewCleanPage = {}
  let pdfNewQuotePage = {}
  let title = "text-2xl mt-2"
  let subtitle = "hidden"
  let quote = "hidden"
  let cleanBlockStyle = blockStyle

  if (pdf) {
    paragraphWidth = "w-1/3"
    paragraphStartGap = "-mt-32"
    title = "text-blue-900 font-semibold text-xl"
    subtitle = "text-green-400"
    blockStyle = "pt-10 pl-16 text-sm text-blue-900"
    cleanBlockStyle = blockStyle + " w-4/5"
    leading = ""
    quote = "relative top-48 pl-80 text-white text-2xl font-semibold"
    pdfNewCleanPage = {
      height: '1130px',
      background: 'url(/images/pdf-clean-page.png)',
      backgroundSize: 'cover',
      pageBreakBefore: 'always'
    }
    pdfNewQuotePage = {
      height: '1130px',
      background: 'url(/images/pdf-quote-page.png)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      pageBreakBefore: 'always'
    }
  }

  return (
    <>
      <div className={blockStyle} style={pdfNewQuotePage}>
        <div className={title}>Understanding your results</div>
        <div className={subtitle}>Performance Indicator Assessment Report</div>
        <div className={quote}>
          <div className="relative pl-2 pt-2" style={{width: "10em", margin: 'auto', height: "10em"}}>
            Without continual growth and progress, such words as improvement, achievement, and success have no meaning.
          </div>
          <div className="text-sm text-gray-100 italic pl-24 pt-10">Benjamin Franklin</div>
        </div>
        <p className={`${paragraphWidth} ${paragraphStartGap} ${leading} text-gray-700`}>
          At Ad Astra Human Performance, we work to inspire and instil self-confidence. The better you understand yourself the easier it is to develop your ultimate potential, both as an individual and a team player.
        </p>
        <p className={`${paragraphWidth} mt-4 ${leading} text-gray-700`}>
          The Ad Astra Performance Indicator Assessment tests you in the nine core Human Competencies that affect your ability to perform to your fullest potential.
        </p>
        <div className={`mt-4 ${leading} text-gray-700`}>
          The core Human Competencies are:
          <ul className={`ml-8 list-disc`}>
            <li>Leadership & Teamwork</li>
            <li>Equality, Diversity, & Inclusion</li>
            <li>Communication</li>
            <li>Situational Awareness</li>
            <li>Time & Workload Management </li>
            <li>Problem Solving & Decision Making</li>
            <li>Stress, Fatigue, & Wellbeing Management</li>
            <li>Human Error & Threat management </li>
            <li>Professional Standards & Self Awareness </li>
          </ul>
        </div>
      </div>

      <div style={pdfNewCleanPage}>
        <div className={cleanBlockStyle}>
          <div className={`mt-4 ${leading}`}><b>Why are Competencies so important?</b></div>
          <p className={`mt-4 ${leading} text-gray-700`}>
            Your greatest chance of success lies in understanding what you naturally do best, and to deploy these qualities, alongside your acquired skills and knowledge, to stop small challenges becoming big problems.
          </p>

          <p className={`mt-4 ${leading} text-gray-700`}>
            This unlocks your ability to optimise your performance and achieve the highest levels of productivity, safety, and success, and will bring enormous benefits to you.
          </p>

          <p className={`mt-4 ${leading} text-gray-700`}>
            Competency is a set of knowledge, abilities, skills, experiences and behaviours, which leads to effective performance; it is measurable and can be developed through training. 
          </p>

          <div className={`mt-4 ${leading}`}><b>What are Competencies?</b></div>

          <p className={`mt-4 ${leading} text-gray-700`}>
            Competencies extend beyond measuring baseline characteristics and skills, to distinguish superior from average performance. They are the skills and behaviours that allow your operation to work well. They increase efficiency and safety where applicable, they unlock potential and inspire commitment and motivation within workforces.
          </p>

          <p className={`mt-4 ${leading} text-gray-700`}>
            Human competency is a set of skills that all human beings have; they allow us to think and act logically, bypassing the emotional part of our brain (the Limbic system) so that we control our actions and behaviours, via the Cortex (the logical part), rather than allowing our emotional instincts to control us.
          </p>

            <p className={`mt-4 ${leading} text-gray-700`}>
              The ability to be logical enables you to be proactive. When things start to go wrong, we can learn to engage our logical brain to control the outcome, consider backup plans, and create our own destiny. 
            </p>

              <div className={`mt-4 ${leading}`}><b>How do skills and competencies differ?</b></div>

              <table className={`mt-4 table-auto`}>
                <tbody>
                  <tr>
                    <td className={`p-2`}></td>
                    <td className={`p-2`}><b>Definition</b></td>
                    <td className={`p-2`}><b>Examples</b></td>
                  </tr>
                  <tr>
                    <td className={`p-2`}><b>Skills</b></td>
                    <td className={`p-2`}>Specific learned abilities that you will require to perform a given job successfully</td>
                    <td className={`p-2`}>Handling accounts, coding, welding, writing tenders, computer programming, foreign language proficiency</td>
                  </tr>
                  <tr>
                    <td className={`p-2`}><b>Competencies</b></td>
                    <td className={`p-2`}>Knowledge and behaviours that lead you to be successful in a job</td>
                    <td className={`p-2`}>Analytical ability, problem-solving, initiative, negotiation, improving business processes, strategic planning, data-based decisions</td>
                  </tr>
                </tbody>
              </table>

              <p className={`mt-4 ${leading} text-gray-700`}>
                Some people are better at engaging their logical brain than others, but we all have these core human competencies.  Often it requires training and understanding (plus a recognition that they exist within us) to draw them out and enable people to perform to the absolute best of their abilities. By doing so, they experience a fulfilling life and a successful career.
              </p>

                <p className={`mt-4 ${leading} text-gray-700`}>
                  Correcting errors are expensive, leveraging these competencies have been found to reduce incidents by a minimum of 74%. 
                </p>

    </div>
    </div>


    <div style={pdfNewCleanPage}>
      <div className={cleanBlockStyle}>
        <div className={`mt-4 ${leading}`}><b>Where Do You Stand?</b></div>


        <p className={`mt-4 ${leading} text-gray-700`}>
          The following phrases are based on an old Chinese proverb that helps to explain the differing levels of self-awareness and willingness to grow and develop. The aim is to become the Conscious Competent.
        </p>

        <div className={`mt-4 ${leading}`}><b>Conscious Incompetent</b></div>
        <p className={`${leading} text-gray-700`}>
          They who do not know, and are aware that they do not know – teach them. 
        </p>

        <div className={`mt-4 ${leading}`}><b>Unconscious Incompetent</b></div>
        <p className={`${leading} text-gray-700`}>
          They who do not know, and are not aware that they do not know – help them.
        </p>

          <div className={`mt-4 ${leading}`}><b>Unconscious Competent</b></div>
          <p className={`${leading} text-gray-700`}>
            They who know, and are not aware that they know – show them. 
          </p>

            <div className={`mt-4 ${leading}`}><b>Conscious Competent</b></div>
            <p className={`${leading} text-gray-700`}>
              They who know, but are aware that they know, are wise – follow them.
            </p>

              <p className={`mt-4 ${leading} text-gray-700`}>
                Understanding and strengthening all aspects of your Competency Wheel will enable you to become the Conscious Competent. The Ad Astra Performance Indicator Assessment is designed to enhance your self-awareness. You can then leverage your strengths and competencies for a positive impact on the way you live and work.
              </p>

                <div className={`mt-4 ${leading}`}><b>In the Green: It comes naturally</b></div>
                <p className={`mt-4 ${leading} text-gray-700`}>
                  The Conscious Competent - They who know, but are aware that they know, are wise – follow them.
                </p>

                  <p className={`mt-4 ${leading} text-gray-700`}>
                    You start from a position of strength by exploring and understanding what you naturally excel at. The real test is being able to replicate your performance when under pressure; to perform the competency reliably and accurately you must understand why you are good at it. We will explore these processes with you in this report to make you the Conscious Competent.  Think about the processes you use and make a simple plan of action. 
                  </p>

                    <p className={`mt-4 ${leading} text-gray-700`}>
                      Maintain your competitive advantage; learn how to leverage your green strengths to the fullest. Our courses have been designed not only to develop those skills that do not come naturally to you, but to drive you further into the green and strengthen these competencies. You have the skills – we can show you how to apply them to your fullest potential.
                    </p>
    </div>
    </div>

    <div style={pdfNewCleanPage}>
      <div className={cleanBlockStyle}>
        <div className={`mt-4 ${leading}`}><b>In the Middle - Amber: Strengthen your awareness</b></div>

        <p className={`mt-4 ${leading} text-gray-700`}>
          The Unconscious Incompetent/Competent - They who do not know, and are not aware that they do not know – help them.
          They who know, and are not aware that they know – show them. 
        </p>


        <p className={`mt-4 ${leading} text-gray-700`}>
          You display a good understanding of the knowledge and behaviours that make you effective, giving a solid base on which to stride forward to the most effective level, but the desired outcome may not always be observed.  Enhancing your understanding and awareness of these competencies can drive you to the next level together with greater reward and fulfilment. 
        </p>

        <p className={`mt-4 ${leading} text-gray-700`}>
          This report and the Ad Astra training programmes are designed to help you strengthen your awareness and show you the tools and methodologies to do this.  
        </p>

        <div className={`mt-4 ${leading}`}><b>In the Red: It does not come naturally</b></div>

        <p className={`mt-4 ${leading} text-gray-700`}>
          The Conscious Incompetent - They who do not know, and are aware that they do not know – teach them.
        </p>

        <p className={`mt-4 ${leading} text-gray-700`}>
          Although the lower scoring competencies may seem a hurdle to your performance, it is important to understand that they are not necessarily a weakness, but more a springboard to recognise that they do not come naturally to you. You should action a plan to take yourself direct to the most effective level. 
        </p>

        <div className={`mt-4 ${leading}`}>
          Ask yourself:
          <ul className={`ml-8 list-disc`}>
            <li>Has this competency ever undermined your success?</li>
            <li>Have you ever received negative feedback related to this competency?</li>
            <li>Do you need to use this competency regularly, but feel drained when you do?</li>
          </ul>
        </div>

        <p className={`mt-4 ${leading} text-gray-700`}>
          The good news is that you have created an awareness, and now need some direction to apply the competency to your fullest potential. 
        </p>

        <p className={`mt-4 ${leading} text-gray-700`}>
          You can learn to be proactive by engaging your logical brain and controlling the outcome to any situation. 
        </p>

        <p className={`mt-4 ${leading} text-gray-700`}>
          The Ad Astra methodologies challenge the status quo in their design so you can remain ahead of others by developing your skills using and applying competency framework.  
        </p>
      </div>
    </div>
    </>
  )
}

export const Outcome = ({pdf}: {pdf: boolean}): ReactElement => {
  let classes = ""
  let styling = {}
  let pdfContentClasses = ""
  let chartClasses = "mt-8 rounded shadow-xl"

  const pdfNewCleanPage = {
    height: '1130px',
    background: 'url(/images/pdf-clean-page.png)',
    backgroundSize: 'cover',
    pageBreakBefore: 'always'
  }

  if (pdf) {
    classes = "pt-16 pl-16 text-xs"
    styling = pdfNewCleanPage
    pdfContentClasses = "w-2/3"
    chartClasses = "mt-8 w-11/12"
  } else {
    styling = {maxWidth: '48rem'}
  }

  return (
    <>
      <div className={classes} style={styling}>
        <div className="mt-8 text-xl font-bold">The Outcome</div>
        <img className={chartClasses} src="/images/performance.png" />

        <div className={pdfContentClasses}>
          <div className="mt-8 font-semibold">
            Become the Conscious Competent 
          </div>
          <div className="mt-4">
            As we have said before, we all have these competencies. Those that do not come as naturally to you as others require two steps in order to be enhanced:
          </div>
          <ul className="list-disc ml-8 mt-4">
            <li>Recognising and understanding that there is a great opportunity to develop on a personal and professional level.</li>
            <li>Using this opportunity to train these skills and competencies to enhance your performance and realise your fullest potential.</li>
          </ul>
        </div>
      </div>

      <div className={classes} style={styling}>
        <div className={pdfContentClasses}>
          <div className="mt-8 font-semibold">
            Optimising your Performance
          </div>

          <div className="mt-4">
            As you can see from the Ad Astra Graph of Optimum Performance, there are two types of “limiting factors” – under performance and over performance.
          </div>

          <div className="mt-4">
            The first of our limiting factors is under performing as shown by the blue line on the graph. These are internal obstacles such as Noise, Fear, Doubts, Self-Criticism, Limiting Beliefs and assumptions, or external ones such as being belittled by others or feeling unappreciated by a manager. However, internal factors are often much more limiting than external factors.
          </div>

          <div className="mt-4">
            The second limiting factor is over performing as shown by the purple line our the graph. This can be considered a product of our own success and performance. As we improve, we acquire greater awareness, and more responsibility can be loaded onto us. Without careful management, factors such as stress, being overworked, or overloaded, can weigh heavily on us, with the consequence of reducing performance in the long run.
          </div>


            <div className="mt-4">
              How can we bring ourselves to the point of optimum performance?
            </div>

            <div className="mt-4">
              We do this by playing to our strengths and doing the things that come naturally to us – applying your “in the green” competencies. By doing more of the things you are good at, you can overcome your doubts, fears, and self-criticism, which will enable you to perform to your fullest potential.
            </div>

              <div className="mt-4">
                By applying your interpersonal skills, strengths, and human competencies to the best of your ability, you will bring yourself down the curve to the point of optimum performance, while expanding the area of high productivity. This reduces the likelihood of reaching the “tipping point” through stress and overload. 
              </div>

                <div className="mt-4">
                  This will maximise your productivity and enable you to reach your fullest human potential, while experiencing fulfilment and success. 
                </div>

                  <div className="mt-8 font-semibold">
                    Realise your Potential
                  </div>

                    <div className="mt-4">
                      Pandemics, globalisation, digitalisation, economic crises, corporate social responsibilities, continuing environmental issues and instant communication, all demand qualities in a workforce that are agile and robust.
                    </div>

                      <div className="mt-4">
                        Our Industry leading Training and Development Programmes incorporate the human competencies covered in this report and have been developed over many years of research and practice. We are recognised for continually delivering engaging and innovative learning solutions that are tailored to you with the unique advantage of proven return on investment. At the Heart of this process is unlocking the potential of people as strong performers.
                      </div>

                        <div className="mt-4">
                          As you learn you will become more instinctive and by understanding what does and does not come naturally to you, you will become the master of your Competency Wheel. You have the power to be the best version of yourself. 
                        </div>

                          <div className="mt-4">
                            As the World continues to change around us, by engaging your intrinsic motivation, the concepts covered throughout your report will enable you to fearlessly adapt and develop to remain ahead and continue driving yourself towards an exciting and successful future.
                          </div>
    </div>
    </div>
    </>
  )
}

const Results = ({questionnaire, user, state}) => {
  const max = CalculateMaxScore(questionnaire, state.answers)
  const results = Mark(questionnaire, state.answers)
  const percentiles = MarkAsPercentile(questionnaire, state.answers)

  const DownloadButton = ({label, className = "", userName, answers}: {label: string, className: string, userName: string, answers: Answers}) => {
    const classes = "disabled:opacity-50 inline-block p-2 rounded hover:bg-indigo-800 transition duration-500 bg-indigo-600 text-indigo-100 select-none cursor-pointer " + className

    return (
      <a href={'/api/pdf/assessment-results.pdf?userName=' + btoa(userName) + '&answers=' + btoa(JSON.stringify(answers))} className={classes}>{label}</a>
    )
  }

  return (
    <div className="fade" style={{maxWidth: '70rem'}}>
      <div className="flex justify-between items-center" style={{maxWidth: '48rem'}}>
        <div className="my-4 text-4xl">{user.name}'s Results</div>
        <DownloadButton label="Download PDF" userName={user.name} answers={state.answers}/>
      </div>

      <div className="mt-4 leading-6"><b>{user.name}'s Competency Wheel</b></div>
      <div className="w-2/3 mb-4">
        <Chart percentiles={percentiles} />
      </div>

      <Understanding pdf={false}/>

      <div className="mt-4 leading-6"><b>Your Competency Wheel</b></div>
      <div className="w-2/3 mb-4">
        <Chart percentiles={percentiles} />
      </div>

      <ResultDescription shorthand="ltw" title={attributes.ltw} score={percentiles.ltw} pdf={false}/>
      <ResultDescription shorthand="edi" title={attributes.edi} score={percentiles.edi} pdf={false}/>
      <ResultDescription shorthand="com" title={attributes.com} score={percentiles.com} pdf={false}/>
      <ResultDescription shorthand="saw" title={attributes.saw} score={percentiles.saw} pdf={false}/>
      <ResultDescription shorthand="twm" title={attributes.twm} score={percentiles.twm} pdf={false}/>
      <ResultDescription shorthand="pdm" title={attributes.pdm} score={percentiles.pdm} pdf={false}/>
      <ResultDescription shorthand="sfw" title={attributes.sfw} score={percentiles.sfw} pdf={false}/>
      <ResultDescription shorthand="etm" title={attributes.etm} score={percentiles.etm} pdf={false}/>
      <ResultDescription shorthand="pro" title={attributes.pro} score={percentiles.pro} pdf={false}/>

      <Outcome pdf={false}/>
    </div>
  )
}

export default Results
