const answerToNumber = (a) => {
  return {
    "a": 1,
    "b": 2,
    "c": 3,
    "d": 4,
    "e": 5
  }[a]
}

export const emptyResults = {
  "ltw": 0, 
  "edi": 0,
  "com": 0,
  "saw": 0,
  "twm": 0,
  "pdm": 0,
  "sfw": 0,
  "etm": 0,
  "pro": 0
};

export const CalculateMaxScore = (questionnaire) => {
  const results = Object.assign({}, emptyResults)
  const questions = [].concat.apply([], questionnaire.scenarios.map(s => s.questions))
  const matrixA = questionnaire.markingMatrixA
  const matrixB = questionnaire.markingMatrixB

  questions.map((q) => {
    const weightA = matrixA[q.bestAnswer-1][q.bestAnswer-1]
    const weightB = matrixB[q.bestAnswer-1][q.bestAnswer-1]

    q.competencies.a.forEach(c => results[c] += weightA)
    q.competencies.b.forEach(c => results[c] += weightB)
  })

  return results
}

export const Mark = (questionnaire, answers) => {
  const results = Object.assign({}, emptyResults)
  const questions = [].concat.apply([], questionnaire.scenarios.map(s => s.questions))
  const matrixA = questionnaire.markingMatrixA
  const matrixB = questionnaire.markingMatrixB

  if (questions.length !== answers.length) {
    console.error('different number of questions than answers')
  }

  answers.map((a, i) => {
    const q = questions[i]
    const weightA = matrixA[answerToNumber(a)-1][q.bestAnswer-1]
    const weightB = matrixB[answerToNumber(a)-1][q.bestAnswer-1]

    q.competencies.a.forEach(c => results[c] += weightA)
    q.competencies.b.forEach(c => results[c] += weightB)
  })

  return results
}

export const MarkAsPercentile = (questionnaire, answers) => {
  const marks = Mark(questionnaire, answers)
  const max = CalculateMaxScore(questionnaire, answers)

  return {
    "ltw": parseInt((marks["ltw"] / max["ltw"]) * 100), 
    "edi": parseInt((marks["edi"] / max["edi"]) * 100),
    "com": parseInt((marks["com"] / max["com"]) * 100),
    "saw": parseInt((marks["saw"] / max["saw"]) * 100),
    "twm": parseInt((marks["twm"] / max["twm"]) * 100),
    "pdm": parseInt((marks["pdm"] / max["pdm"]) * 100),
    "sfw": parseInt((marks["sfw"] / max["sfw"]) * 100),
    "etm": parseInt((marks["etm"] / max["etm"]) * 100),
    "pro": parseInt((marks["pro"] / max["pro"]) * 100)
  }
}
