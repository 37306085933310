import React from 'react'

const reportContent = {
  ltw: {
    opening:
      <>
        <div className="mt-2">
          What does it feel like to inspire a dynamic group of people and lead them to success? 
        </div>
        <div className="mt-2">
          What does it feel like to be part of a cohesive and well-balanced team?
        </div>
      </>,
    quote: "Of all the things I’ve done, the most vital is coordinating those who work with me and aiming their efforts at a certain goal.",
    quoteCredit: 'Walt Disney',
    preamble:
      <>
        <div className="mt-2">
          Leadership and Teamwork go hand in hand as the success or failure of leadership can be dictated by the individuals within a group, and vice versa.
        </div>
        <div className="mt-2">
          A leader can be thought of as a person whose actions, ideas and behaviours influence the actions and behaviours of others. Successful teamwork requires effective leadership and cooperation from all team members, where the power of combined effort is greater than the sum of its parts.
        </div>
        <div className="mt-2">
          Teams exist in every walk of life, and often people don’t realise this. Colleagues may feel they are working in an effective team, but once you scratch beneath the surface more often than not you will see this is not the case. 
        </div>
        <div className="mt-2">
          Strong performing teams have an aligned purpose and a common goal, with a leader who recognises how important this is for success. A team with a common purpose, with a stable leader who motivates and inspires, can experience successes that are truly remarkable.  
        </div>
        <div className="mt-2">
          How often is an expensively assembled sports team outperformed by lesser talents with a charismatic leader with vision and a plan?
        </div>
        <div className="mt-2">
          They collectively overcome any challenges they face, leaving them feeling empowered and fulfilled. 
        </div>
        <div className="mt-2">
          <b>How can you be a good team member?</b>
          <ul className="list-disc mt-2 ml-8">
            <li>Be proactive in creating and maintaining a strong network of contacts and working relationships across your organisation.</li>
            <li>
              Work with a culture that promotes openness and honesty by sharing ideas through strong communication, being inclusive, and supporting your colleagues, especially when facing challenging situations. 
            </li>
          </ul>
        </div>
      </>,
    good:
      <>
        <p className="mt-2">
          Your result indicates that you are a strong performer in Leadership and Teamwork and understand how to apply these skills in the real world. 
        </p>
        <p className="mt-2">
          Take the time now to reflect on why you may be a good team player and leader, and consciously continue to apply this every day.
        </p>
        <ul className="ml-8 list-disc mt-2">
          <li>People who perform highly in Leadership and Teamwork often take the initiative to inspire and motivate their team.</li>
          <li>They ensure that everyone has the opportunity to take part if required, and will consider all suggestions, even if these differ from their own.</li>
          <li>They are good at maintaining an open working atmosphere and understand when the right moments appear for advocating their position.</li>
          <li>They are empathetic and support their colleagues, demonstrating a duty of care towards them</li>
          <li>They are able to delegate tasks and are not afraid to ask for help if they need it.</li>
          <li>Good team members are open to receiving feedback and give feedback to others in a positive way and do not like to create any unnecessary conflict. However, if conflict were to arise, they quickly try to resolve it.</li>
        </ul>
      </>,
    bad:
      <>
        <p className="mt-2">
          Your result indicates that you are a good performer in Leadership and Teamwork, and it is something you consciously work at. This is now a great opportunity to strengthen your awareness and improve your leadership and teamwork skills even more.
        </p>
        <p className="mt-2">
          The following action points will help you to strengthen your competency further:
        </p>
        <ul className="ml-8 list-disc mt-2">
          <li>Consider taking the initiative to inspire and motivate the people around you.</li>
          <li>Try to establish a working environment that is open and honest, where ideas can be shared and mistakes learned from.</li>
          <li>Make sure that everyone has the opportunity to take part if required, and consider all suggestions, even if these differ from yours.</li>
          <li>Consider when it is appropriate to advocate your position, or whether it is more appropriate to offer advice at a different time.</li>
          <li>Remember to support your colleagues and ask for help if you need it. Your performance is impacted if you are struggling with a task.</li>
          <li>Be proactive in resolving conflict and try to avoid unnecessary conflict altogether.</li>
          <li>Be open to receiving feedback and give feedback to others that establishes intrinsic motivation to learn and grow. </li>
        </ul>
      </>,
    ugly:
        <>
          <p className="mt-2">
            Your result indicates that your Leadership and Teamworking skills have potential for further development.  This is not a weakness, it is an opportunity for personal and professional growth. 
          </p>
          <p className="mt-2">
            The following action points will help you to develop this competency:
          </p>

          <ul className="ml-8 list-disc mt-2">
            <li>Consider taking initiative to inspire and motivate the people around you.</li>
            <li>Try to establish a working environment that is open and honest, where ideas can be shared and mistakes learned from.</li>
            <li>Make sure that everyone has the opportunity to take part if required, and consider all suggestions, even if these differ from yours.</li>
            <li>Consider when it is appropriate to advocate your position, or whether it is more appropriate to offer advice at a different time.</li>
            <li>Remember to support your colleagues and ask for help if you need it. Your performance is impacted if you are struggling with a task.</li>
            <li>Be proactive in resolving conflict and try to avoid unnecessary conflict altogether.</li>
            <li>Be open to receiving feedback and give feedback to others that establishes intrinsic motivation to learn and grow. </li>
          </ul>
        </>
  },

  edi: {
    opening:
      <>
        <div className="mt-2">
          What does it feel like to be excluded? 
        </div>
        <div className="mt-2">
          Do you embrace equality and diversity?  
        </div>
      </>,
    quote: "Diversity is being invited to the party, inclusion is being asked to dance",
    quoteCredit: "Verna Myers",
    preamble:
      <>
        <div className="mt-2"><b>Equality:</b> providing equal opportunities for everyone and treating everyone fairly.</div>
        <div className="mt-2"><b>Diversity:</b> celebrating differences: diverse skills, ideas, and abilities, are recognised and valued.</div>
        <div className="mt-2"><b>Inclusion:</b> everyone feels valued, respected, and included in their role – barriers are overcome, and discrimination doesn’t exist.</div>
        <div className="mt-2">
          Age, gender, race, sexuality – it doesn’t matter who we are or where we come from, we are all Human Beings with a diverse set of talents, strengths, and experiences, that should be celebrated, without allowing stereotypes and our misconceptions to obscure our reasoning. 
        </div>
        <div className="mt-2">
          By celebrating this fact, our performance and professional satisfaction is subconsciously increased. If you do not actively include, you are unintentionally excluding, and that person being excluded may have the vital piece of the puzzle…
        </div>
        <div className="mt-2">
          Should we treat others in the way we would like to be treated ourselves? Or should we actually be treating others in the way they would like to be treated themselves? 
        </div>
        <div className="mt-2"><b>How does EDI work as a Human Competency? </b></div>
        <ul className="ml-8 list-disc mt-2">
          <li>Celebrating Equality & Diversity and incorporating this into the way we work by being Inclusive has proven to enhance professional performance.</li>
          <li>It increases job satisfaction for everyone.</li>
          <li>It opens up an array of different skills, experiences and backgrounds that make individuals, and teams, stronger and more resilient.</li>
          <li>It is vital for effective Leadership & Teamwork.</li>
          <li>Without including everyone, you may be excluding someone with that vital piece of the puzzle that the team needs or, in extreme cases, could even save a life.</li>
        </ul>
      </>,
    good:
        <>
          <div className="mt-2">
            Your result indicates that you have a strong understanding of the concepts behind Equality, Diversity, and Inclusion (EDI), and applying this in the real world comes naturally to you. 
          </div>
          <div className="mt-2">
            Take the time now to reflect on the things you do that celebrate Equality, Diversity, and Inclusion, and consciously continue to apply this every day.
          </div>
          <ul className="ml-8 list-disc mt-2">
            <li>People who recognise the importance of EDI tend to demonstrate a forward thinking and inclusive working culture and use appropriate language that will not offend their colleagues.</li>
            <li>They do not discriminate or belittle anyone, but treat people with dignity and respect, ensuring everyone has an equal opportunity to demonstrate their talents and abilities.</li>
            <li>They celebrate diverse skills and differences, and value different ideas.</li>
            <li>They also try to be proactive in feeding-back unjust, discriminative, or unlawful behaviour to their managers, or calling out people who behave in this way.</li>
            <li>They think about how they can actively and appropriately include everyone, in order not to exclude anyone.</li>
          </ul>
        </>,
    bad:
      <>
        <div className="mt-2">
          Your result indicates that you understand the concepts behind Equality, Diversity, and Inclusion (EDI), but create an opportunity for you to strengthen your awareness and develop your understanding even more.
        </div>
        <div className="mt-2">
          The following action points will help you to strengthen your competency further:
        </div>
        <ul className="ml-8 list-disc mt-2">
          <li>Think about ways to demonstrate a forward thinking and inclusive culture throughout all aspects of life and work.</li>
          <li>Consider whether the language you are using may discriminate, belittle, or offend someone, and think about how it could affect them.</li>
          <li>It is important to celebrate diverse skills and differences, so try to actively include as much as you can to avoid unintentionally excluding which can be harmful to team dynamics.  This allows for equal opportunity for all to demonstrate their talents and abilities.</li>
          <li>Even though people may not discriminate themselves, it is important to challenge this behaviour if seen in others, and be proactive in doing so.</li>
          <li>Think about how you can actively and appropriately include people, in order to avoid being exclusive.</li>
        </ul>
      </>,
    ugly:
      <>
        <div className="mt-2">
          Your result indicates that your understanding of the concepts behind Equality, Diversity, and Inclusion (EDI) do not come naturally to you. This is not a weakness, it is an opportunity for personal development and growth.
        </div>
        <div className="mt-2">
          The following action points will help you to develop this competency:
        </div>
        <ul className="ml-8 list-disc mt-2">
          <li>Think about ways to demonstrate a forward thinking and inclusive culture throughout all aspects of life and work.</li>
          <li>Consider whether the language you are using may discriminate, belittle, or offend someone, and think about how it could affect them.</li>
          <li>It is important to celebrate diverse skills and differences, so try to actively include as much as you can to avoid unintentionally excluding which can be harmful to team dynamics. This allows for equal opportunity for all to demonstrate their talents and abilities.</li>
          <li>Even though people may not discriminate themselves, it is important to challenge this behaviour if seen in others, and be proactive in doing so.</li>
          Think about how you can actively and appropriately include people, in order to avoid being exclusive.
        </ul>
      </>
  },

  com: {
    opening:
      <>
        <div className="mt-2">
          What does effective communication look like to you? 
        </div>
        <div className="mt-2">
          What does it feel like to be part of a cohesive and well-balanced team?
        </div>
      </>,
    quote: "One cannot not communicate.",
    quoteCredit: "Paul Watzlawick",
    preamble:
      <>
        <div className="mt-2">
The effectiveness of all the other competencies covered in this report is highly dependent on communication being effective. The way we communicate can influence the way we react with each other and can have an effect on our relationships and attitudes towards each other. 
        </div>
        <div className="mt-2">
          We are communicating all of the time, even without realising it, as most of our communication is actually non-verbal. It brings us together and allows us to share our knowledge and experiences. However, listening and understanding is just as important as the message itself. 
        </div>
        <div className="italic">We must listen to understand, not listen to reply.</div>
        <div className="mt-2">
          How often have you said “Oh, I thought you meant…” or “I thought you said…”? Communication requires effort and understanding of the message in order to be effective. 
        </div>
        <div className="mt-2">
          The recipient can misinterpret the information that the sender was trying to convey. This, along with barriers that prevent communication from being clear and effective, can lead to a misunderstanding and is often the reason behind conflict.
        </div>
        <div className="mt-2">
          As a species, communication has enabled us not just to survive, but thrive. So in its very purest form, you could say that communication is about survival, but we have used it to come together as the most successful species on the planet. 
        </div>
        <div className="mt-2">
          Effective communication is vital for success and strong performance. Without it, the system breaks down. 
        </div>
      </>,
    good:
        <>
          <div className="mt-2">
            Your result indicates that you have a strong understanding of how to communicate effectively. 
          </div>
          <div className="mt-2">
            Take the time now to reflect on the way you communicate and continue to apply this every day.
          </div>
          <ul className="ml-8 list-disc mt-2">
            <li>People who are strong communicators tend to convey their information clearly and maturely at an appropriate time, while using relevant words that the person they are communicating with will understand.</li>
            <li>They recognise the importance of keeping their conversations open and interactive, as well as using appropriate body language to enhance their conversations</li>
            <li>Good communicators will try to overcome any obstacles that prevent clear communication, and will check that the person they are talking to understands their message.</li>
            <li>When conversing with another person, a good communicator will listen carefully to what is being said in order to understand the relevance of the conversation.</li>
            <li>If they do not understand something a person is trying to tell them, they will ask questions to resolve this misunderstanding.</li>
          </ul>
        </>,
    bad:
      <>
        <div className="mt-2">
          Your result indicates that you understand the concept of effective communication but creates an opportunity for you to strengthen your awareness of this skill and develop it further.
        </div>
        <div className="mt-2">
          The following action points will help you to strengthen your competency further:
        </div>
        <ul className="ml-8 list-disc mt-2">
          <li>Make sure you convey information clearly and maturely, and use body language that enhances the message you are trying to give.</li>
          <li>Use words and phrases that the people you are communicating with will understand to avoid any misunderstanding. </li>
          <li>Try to keep your conversations open, engaging, and interactive.</li>
          <li>Think about ways that you can overcome obstacles that prevent clear communication (for example, poor telephone connection or facemasks), and check that the person you are talking to fully understands your message.</li>
          <li>It is so important to listen carefully to what is being said in order to understand the relevance of a conversation. As we said before, do not listen to reply, listen to understand.</li>
        </ul>
      </>,
    ugly:
      <>
        <div className="mt-2">
          Your result indicates that your communication skills may not come naturally to you. As before, it is important to remember that this does not mean this is a weakness, it is an opportunity for personal development and growth.
        </div>
        <div className="mt-2">
          The following action points will help you to develop this competency:
        </div>

        <ul className="ml-8 list-disc mt-2">
          <li>Make sure you convey information clearly and maturely, and use body language that enhances the message you are trying to give.</li>
          <li>Use words and phrases that the people you are communicating with will understand to avoid any misunderstanding. </li>
          <li>Try to keep your conversations open, engaging, and interactive</li>
          <li>Think about ways that you can overcome obstacles that prevent clear communication (for example, poor telephone connection or facemasks), and check that the person you are talking to fully understands your message.</li>
          It is so important to listen carefully to what is being said in order to understand the relevance of a conversation. As we said before, do not listen to reply, listen to understand.
        </ul>
      </>
  },

  saw: {
    opening:
      <>
        <div className="mt-2">
          This is all about seeing and understanding the big picture.
        </div>
        <div className="mt-2">
          Have you ever experienced waves of stress when feeling lost or confused?
        </div>
      </>,
    quote: "The difference between being a victim and a survivor is often a low level of situational awareness.",
    quoteCredit: "Barry Eisler",
    preamble:
      <>
        <div className="mt-2">
          Situational awareness is the perception of our environment and events with respect to time or space, the comprehension of their meaning, and the projection of their future status. In short, it is all about perceiving information, comprehending that information, and then projecting that information into the future to predict any changes. 
        </div>
        <div className="mt-2">
          Our situation changes all of the time, so we must continue to adapt our understanding and try to think ahead. It can become vulnerable during periods of high workload when our capacity to process information is exceeded.
        </div>
        <div className="mt-2">
          Picture yourself trying to run up a down-going escalator. The speed of the escalator represents how quickly your situation changes and how much workload you have.
        </div>
        <div className="mt-2">
          When the escalator moves slowly (high levels of Situational Awareness, stable situation, low workload) we can climb towards the top – thinking ahead. In the real world, thinking ahead means predicting any spikes in workload and considering unknown situations. Then, you can plan to manage and mitigate the effects of them, or even avoid these situations altogether. 
        </div>
        <div className="mt-2">
          There may be times when the escalator is moving faster than we are able to climb it, which will limit our ability to think ahead. It is during these events that our prior thinking ahead prevents us from falling off the bottom of the escalator. As before, it is this prior thinking ahead that prevents us from making mistakes, suffering from high workload, and enables us to perform at our best.
        </div>
        <div className="mt-2">
          We live and work in a complex society, where change is all around us. To be a strong performer, not only do we have to keep up, but we must work hard to stay ahead.
        </div>
      </>,
    good:
      <>
        <div className="mt-2">
          Your result indicates that you are that you are good at maintaining Situational Awareness, and these skills may come naturally to you. 
        </div>
        <div className="mt-2">Take the time now to reflect on the things you do to maintain your awareness and continue to apply this every day.</div>
        <ul className="ml-8 list-disc mt-2">
          <li>People with good Situational Awareness skills maintain an awareness of their environment and the people involved or affected by the task in hand</li>
          <li>They recognise the signs and clues to a loss or lack of understanding and awareness, and attempt to rebuild this.</li>
          <li>They will not allow preconceptions to affect their understanding of a situation.</li>
          <li>People who have a strong awareness will often consider not just what to do, but how they will do it, while thinking ahead about whether the situation could change.</li>
          <li>They use every resource that is available to them in order to enhance their awareness, as well as sharing ideas and thought process with others.</li>
          They will review their understanding and share their thoughts with their colleagues. 
        </ul>
      </>,
    bad:
      <>
        <div className="mt-2">
          Your result indicates that you understand how to maintain good Situational Awareness, but you may not always see the big picture. This is a great opportunity to strengthen your awareness and develop this skill further.
        </div>
        <div className="mt-2">
          The following action points will help you to strengthen your competency further:
        </div>

        <ul className="ml-8 list-disc mt-2">
          <li>Remember to share your ideas, understanding, and thought processes with other. This will allow you to check how well you understand a situation and ensure that you are on the same page as everyone else.</li>
          <li>Make sure you do not allow preconceptions to affect your understanding of a situation.</li>
          <li>When carrying out a task, don’t just consider what you need to do, think about how you will do it. This will increase the success of the outcome.</li>
          <li>Try to use every resource that is available to you, including other people, especially when attempting to regain your awareness and understanding.</li>
          <li>Think ahead about how a situation could change, particularly during periods of lower workload.</li>
          <li>Try to recognise the signs that you are lacking in understanding, or losing awareness, for a given task. (For example, through stress, high workload, confusion.</li>
        </ul>
      </>,
    ugly:
      <>
        <div className="mt-2">
          Your result indicates that the ability to maintain high levels of Situational Awareness may not come naturally to you. Don’t forget, this does not mean this is a weakness, it is an opportunity for your personal development and growth.
        </div>
        <div className="mt-2">The following action points will help you to develop this competency:</div>

        <ul className="ml-8 list-disc mt-2">
          <li>Remember to share your ideas, understanding, and thought processes with other. This will allow you to check how well you understand a situation and ensure that you are on the same page as everyone else.</li>
          <li>Make sure you do not allow preconceptions to affect your understanding of a situation.</li>
          <li>When carrying out a task, don’t just consider what you need to do, think about how you will do it. This will increase the success of the outcome.</li>
          <li>Try to use every resource that is available to you, including other people, especially when attempting to regain your awareness and understanding.</li>
          <li>Think ahead about how a situation could change, particularly during periods of lower workload</li>
          <li>Try to recognise the signs that you are lacking in understanding, or losing awareness, for a given task. (For example, through stress, high workload, confusion.</li>
        </ul>
      </>
  },

  twm: {
    opening:
      <>
        <div className="mt-2">
          What goes through your mind when you are running short of time? 
        </div>
        <div className="mt-2">
          How do you feel when your workload is high?
        </div>
      </>,
    quote: "Either you run the day, or the day runs you.",
    quoteCredit: "Jim Rohn",
    preamble:
      <>
        <div className="mt-2">
          Workload is essentially the amount of mental effort required, and expended, in order to process information. 
        </div>
        <div className="mt-2">
          Just like Communication, Time and Workload Management skills are linked to almost all other competencies covered in this report as part of the Competency Wheel. 
        </div>
        <div className="mt-2">Increasing workload can feel stressful, so it is at this point that we must engage the logical side of our brain to control these emotions and regain control to remain productive. 
        </div>
        <div className="mt-2">
          A lack of time, coupled with high workload, is associated with increased errors, fatigue, task degradation and poor performance, and multiple or complex tasks normally require a lot of attention to learn, which increases our workload. 
        </div>

        <div className="mt-2">
          However, the Human Brain is intelligent, and has a strategy of workload reduction to turn repeated tasks into skilled routines to be performed without demanding attention, and therefore generating workload. Without this strategy, everyday life tasks would be impossible.
        </div>
        <div className="mt-2">
          Just like learning how to drive a manual car, or using a new computer system – it takes some getting used to, but after a while it becomes much more straightforward so we have more capacity for other things. 
        </div>
      </>,
    good:
      <>
        <div className="mt-2">
          Your result indicates that you understand how to manage your time and workload well and these skills may come naturally to you. 
        </div>
        <div className="mt-2">
          Reflect on the things you do to manage your workload and make sure to continue applying this every day.
        </div>
        <ul className="ml-8 list-disc mt-2">
          <li>People who are strong in this area prioritise and plan their tasks, will act calmly and methodically with a high workload, and will try to keep track of time as often as they can.</li>
          <li>They will manage any distractions and try to think ahead during periods of low workload, anticipating periods of high workload and attempting to manage or create spare time accordingly.</li>
          <li>People with stronger Workload Management skills can recognise when they start to feel overloaded and will ask for help early on, or delegate tasks, as required to increase their capacity and reduce their workload.</li>
          <li>They try to use all available resources to complete a task and manage their workload.</li>
          </ul>
      </>,
    bad:
      <>
        <div className="mt-2">
          Your result indicates that you understand how to manage your time and workload, however now is great time to strengthen your awareness and see this as an opportunity to develop this skill further.
        </div>
        <div className="mt-2">
          The following action points will help you to strengthen your competency further:
        </div>

        <ul className="ml-8 list-disc mt-2">
          <li>Remember to prioritise and plan your tasks by thinking ahead during periods of lower workload</li>
          <li>Try to engage the logical side of your brain by acting methodically when experiencing a high workload</li>
          <li>Remember to keep track of time. It helps to consider how long a task may take before starting it.</li>
          <li>Distractions may hinder your ability to fully manage your workload and will result in tasks taking longer to complete, so try to manage your time and distractions accordingly</li>
          <li>Try to recognise the signs and sensations of becoming overloaded, such as feeling stressed or anxious, agitated, or hot and flustered. </li>
          <li>Make sure you ask for help or delegate tasks if you feel overloaded. This is vital for increasing capacity and reducing workload, which will have a substantial effect on your performance.</li>
        </ul>
      </>,
    ugly:
      <>
        <div className="mt-2">
          Your result indicates that Time and Workload Management skills may not come naturally to you. Remember, this is not a weakness, it is an opportunity for you to grow and develop on a personal and professional level.
        </div>
        <div className="mt-2">
          The following action points will help you to develop this competency:
        </div>

        <ul className="ml-8 list-disc mt-2">
          <li>Remember to prioritise and plan your tasks by thinking ahead during periods of lower workload.</li>
          <li>Try to engage the logical side of your brain by acting methodically when experiencing a high workload.</li>
          <li>Remember to keep track of time. It helps to consider how long a task may take before starting it.</li>
          <li>Distractions may hinder your ability to fully manage your workload and will result in tasks taking longer to complete, so try to manage your time and distractions accordingly.</li>
          <li>Try to recognise the signs and sensations of becoming overloaded, such as feeling stressed or anxious, agitated, or hot and flustered.</li>
          <li>Make sure you ask for help or delegate tasks if you feel overloaded. This is vital for increasing capacity and reducing workload, which will have a substantial effect on your performance.</li>
        </ul>
      </>
  },

  pdm: {
    opening:
      <>
        <div className="mt-2">
          How do you know if you have made the right decision?
        </div>
      </>,
    quote: "We cannot solve our problems with the same level of thinking that created them.",
    quoteCredit: "Albert Einstein",
    preamble:
      <>
        <div className="mt-2">
          Simply put, Problem Solving & Decision Making is the process of making a judgement, choosing an option, and agreeing a best course of action.
        </div>
        <div className="mt-2">
          We spend our whole lives solving problems and making decisions, all day, every day. There is a lot of theory behind the decision making and critical thinking processes we use as human beings, where the decisions we make every day range from being intuitive (like applying the breaks in your car when you need to slow down) to rational, where you need to spend a while solving a problem and deciding the best course of action. 
        </div>
        <div className="mt-2">
          As Albert Einstein was implying, this can take up a lot of mental capacity, so sharing this process with others is important. The key is to understand the importance of reviewing your course of action once a decision has been made in order to reach an optimum outcome.
        </div>
        <div className="mt-2">
          Effective Problem Solving and Decision Making can be influenced by higher levels of Situational Awareness, with a low workload, to create capacity for obtaining new and relevant information in order to solve a problem, or make a decision.
        </div>
        <div className="mt-2">
          We must be aware of the effect a fast pace and busy working life can have on our ability to make sensible decisions as stress and a lack of time leads to rushed and ineffective decision making. Natural biases also have an effect on the way we make decisions and reach conclusions as they can cause us to misjudge a situation which has a negative effect on our ability to perform well.
        </div>
        <div className="mt-2">
          Have you ever made a bad decision? If so, what would you do differently next time?
        </div>
      </>,
    good:
      <>
        <div className="mt-2">
          Your result indicates that you understand how to solve problems and make decisions effectively. 
        </div>
        <div className="mt-2">
          Take time to reflect on why these skills may come naturally to you and make sure to continue applying this every day.
        </div>
        <ul className="ml-8 list-disc mt-2">
          <li>People with strong Problem Solving and Decision-Making skills recognise when a problem arises and will use all resources available to them to understand and correctly diagnose the problem, and then act on it in an appropriate amount of time.</li>
          <li>They will often ask other colleagues to help them reach a decision, and will listen to alternative options and suggestions, even if those differ from theirs.</li>
          <li>People who are a good at making decisions will think about the consequences, and the benefits and risks, of making a particular decision which reduces the likelihood of an inadvertent human error</li>
          <li>Once a decision is made, strong decision-makers communicate this with their colleagues, and then review, validate, or adapt their plan as required to reach the best outcome</li>
        </ul>
      </>,
    bad:
      <>
        <div className="mt-2">
          Your result indicates that you understand how to solve problems and make decisions, however this is an opportunity for you to strengthen your awareness and develop the processes behind your decision-making skills further.
        </div>
        <div className="mt-2">
          The following action points will help you to strengthen your competency further:
        </div>
        <ul className="ml-8 list-disc mt-2">
          <li>Make sure you act on a problem as soon as it arises as delaying could cause more harm than good.</li>
          <li>Use all resources available to you, including other people, to help you understand and correctly diagnose a problem. You can also think about using a decision-making tool or processes to help structure your decision-making.</li>
          <li>Ask other colleagues to help you reach a decision as they may have thought of something you have not and listen to alternative options and suggestions from others.</li>
          <li>Think about the consequences, and the benefits and risks, of making a particular decision. This will reduce the chance of encountering threats and making human errors.</li>
          <li>Remember to communicate with your colleagues while solving problems and making decisions as this will increase the likelihood of reaching an optimal outcome.</li>
          <li>After you have made a decision, review your outcome by validating whether this was the best course of action or whether an alternative would have been better, and adapt your plans accordingly.</li>
        </ul>
      </>,
    ugly:
      <>
        <div className="mt-2">
          Your result indicates that Problem Solving and Decision-Making skills may not come naturally to you. As before, this is not a weakness, it is an opportunity for you to grow and develop this skill further to enhance your performance.
        </div>
        <div className="mt-2">
          The following action points will help you to develop this competency:
        </div>
        <ul className="ml-8 list-disc mt-2">
          <li>Make sure you act on a problem as soon as it arises as delaying could cause more harm than good.</li>
          <li>Use all resources available to you, including other people, to help you understand and correctly diagnose a problem. You can also think about using a decision making to help structure your decision-making</li>
          <li>Ask other colleagues to help you reach a decision as they may have thought of something you have not, and listen to alternative options and suggestions from others</li>
          <li>Think about the consequences, and the benefits and risks, of making a particular decision. This will reduce the chance of encountering threats and making human errors</li>
          <li>Communicating with colleagues while solving problems and making decisions is vital, as is reviewing, validating, or adapting plans, in order to reach the best outcome</li>
        </ul>
      </>
  },

  sfw: {
    opening:
      <>
        <div className="mt-2">
          Would you be afraid to ask for help or support if you were struggling? Unfortunately, this feeling is incredibly common.
        </div>
      </>,
    quote: "You can’t always control what goes on outside, but you can always control what goes on inside.",
    quoteCredit: "Wayne Dyer",
    preamble:
      <>
        <ul className="list-disc mt-2 ml-8">
          <li>Stress can be described as the response to unfavourable or adverse environmental conditions, or a state of emotional or mental strain caused be demanding circumstances imposed upon us.</li>
          <li>Fatigue can be described as how likely you are to fall asleep, when you would normally be awake.</li>
          <li>Wellbeing is more than simply being happy. It is about feeling satisfied, fulfilled, and how you cope with day-to-day life.</li>
        </ul>
        <div className="mt-2">
          We face many challenges in life that wear us down, induce stress, or create feelings of anxiety or depression. Having an awareness and understanding of wellbeing and mental health is so important. If we can note our feelings, and learn how to manage them, much of the emotional strain we face can be taken away, and it becomes much easier to live life and perform well.
        </div>
        <div className="mt-2">
          Unfortunately stress is part of human life and it is difficult to live without experiencing some degree of stress. An optimum amount of stress is required for an individual to function efficiently and perform tasks. However, it is important to find ways to manage this stress (which varies from person to person) to prevent it from developing into something that affects your wellbeing. 
        </div>
        <div className="mt-2">
          This is very much a balancing act. We need to balance work with life, our time, our emotions, and be able to recover through proper sleep and rest. We must support others and accept support in return to break through the challenges we may be facing. 
        </div>
        <div className="mt-2">
          As the saying goes; it’s okay not to be okay. So don’t be afraid to ask for support or support others in return – we are only human!
        </div>
      </>,
    good:
      <>
        <div className="mt-2">
          Your result indicates that you understand the importance of being aware of stress, fatigue, and wellbeing and that this has a negative impact on performance and the ability to feel fulfilled. 
        </div>
        <div className="mt-2">
          Take time to reflect on why this may come naturally to you and make sure to continue applying this every day.
        </div>

        <ul className="list-disc mt-2 ml-8">
          <li>People with this awareness recognise the signs and symptoms of stress and fatigue and will act appropriately or actively ask for help and support if they notice these symptoms in themselves or in others.</li>
          <li>They do things that they enjoy to release positive energy and try to maintain a healthy lifestyle with exercise to help refocus.</li>
          <li>If they feel they are stressed or working at maximum capacity, they will share their workload with others, and offer the same in return by supporting others.</li>
          <li>If they are concerned about their wellbeing, or the wellbeing of others, they will offer support or talk to their managers.</li>
          <li>It is really important to find support and report any cases of stress, fatigue, or wellbeing concerns, to stay well and be able to keep performing to your fullest potential.</li>
        </ul>
      </>,
    bad:
      <>
        <div className="mt-2">
          Your result indicates that you have an awareness of stress, fatigue, and wellbeing but may not be fully aware of the negative impact it can have on your performance.
        </div>
        <div className="mt-2">
          The following action points will help you to strengthen your understanding and natural ability to manage stress, fatigue, and wellbeing in the real world:
        </div>

        <ul className="list-disc mt-2 ml-8">
          <li>Try to recognise the signs and symptoms of stress and fatigue and ask for help and support if you notice these symptoms in yourself or in others.</li>
          <li>Do things that you enjoy to release positive energy and try to maintain a healthy lifestyle with exercise to help refocus.</li>
          <li>Look after yourself; sleep if you are tired, eat if you are hungry, and spend time recharging if you need time alone, but remember support is there.</li>
          <li>If you feel stressed, or feel like you are working at maximum capacity, don’t try to push on by yourself, instead ask for support and share your workload with others.</li>
          <li>If you are concerned about your wellbeing, or the wellbeing of others, don’t be afraid to seek support or talk to your managers if you are in a professional environment. </li>
          <li>It is really important to find support and report any cases of stress, fatigue, or any wellbeing concerns. By doing so we can help ourselves to stay well and be able to perform to our fullest potential.</li>
        </ul>
      </>,
    ugly:
      <>
        <div className="mt-2">
          Your result indicates that an understanding of stress, fatigue, and wellbeing management techniques may not come naturally to you. It is important to see this as a positive opportunity for personal growth and to become more aware of how you, or others, are feeling.
        </div>
        <div className="mt-2">
          The following action points will help you to strengthen your understanding and natural ability to manage stress, fatigue, and wellbeing in the real world:
        </div>

        <ul className="list-disc mt-2 ml-8">
          <li>Try to recognise the signs and symptoms of stress and fatigue and ask for help and support if you notice these symptoms in yourself or in others.</li>
          <li>Do things that you enjoy that release positive energy and try to maintain a healthy lifestyle with exercise to help refocus</li>
          <li>Look after yourself; sleep if you are tired, eat if you are hungry, and spend time recharging if you need time alone, but remember support is there</li>
          <li>If you feel stressed, or feel like you are working at maximum capacity, don’t try to push on by yourself, instead ask for support and share your workload with others.</li>
          <li>If you are concerned about your wellbeing, or the wellbeing of others, don’t be afraid to seek support or talk to your managers if you are in a professional environment. </li>
          <li>It is really important to find support and report any cases of stress, fatigue, or any wellbeing concerns. By doing so we can help ourselves to stay well and be able to perform to our fullest potential</li>
        </ul>
      </>
  },

  etm: {
    opening:
      <>
        <div className="mt-2">
          How often do you think about threats to achieving successful outcomes? 
        </div>
        <div className="mt-2">
          Are you afraid of making a mistake?
        </div>
      </>,
    quote: "Mistakes have the power to turn you into something better than you were before..",
    quoteCredit: "Oscar Auliq-Ice",
    preamble:
      <>
        <div className="mt-2">
          A superior person will use their superior judgement to avoid situations that would require the use of superior skills.
        </div>
        <div className="mt-2">
          In practical terms, Human Error & Threat management is about thinking ahead to predict and avoid errors and potential threats, and then manage or mitigate any that occur. It is then important that organisations and teams have an open reporting culture that creates learning and development opportunity from these occurrences.
        </div>
        <li>The optimum state of error management (our first line of defence) is the avoidance of errors before they occur. This may require a high level of situational awareness in order to predict the issues that may cause errors.</li>
        <li>Errors that are not avoided need to be recognised and understood (trapped) immediately so that they are not encountered, or that there are no consequences (our second line of defence).</li>
        <li>On rare occasions, if errors and threats are not trapped, then they might have consequences that will require mitigation (our last line of defence).</li>
        <div className="mt-2">
          How do we do this? By thinking ahead about all eventualities and potential outcomes during periods of low workload and referring to our roles, working methods, and procedures, in order to mitigate the effects of these.
        </div>
        <div className="mt-2">
          We are human beings, so it is only natural that we make errors and mistakes. These cannot be eliminated, but we can use tools and promote sharing and reporting in order to manage them.
        </div>
        <div className="mt-2">
          In short, we must go looking for trouble in order to avoid it. Often if we don’t look for trouble, we will not find it.
        </div>
      </>,
    good:
      <>
        <div className="mt-2">
          Your result indicates that you have a good understanding of how to manage human errors, threats, and risks, and these skills may come naturally to you. 
        </div>
        <div className="mt-2">
          Take time to reflect on why this may come naturally to you and make sure to continue applying this every day.
        </div>

        <ul className="list-disc mt-2 ml-8">
          <li>People who recognise the importance of effective human error and threat management will consider the risks and the benefits of taking a certain course of action</li>
          <li>During periods of low workload, they will try to think ahead to consider unknown scenarios and identify any threats that could prevent a successful outcome.</li>
          <li>They recognise when errors have been made and use their knowledge and procedures to mitigate and manage the effects of them</li>
          <li>They will not purposefully break or violate established rules or procedures for personal gain</li>
          <li>It is important to work within a culture that, in the event of Human Error, promotes open reporting to reach a fair outcome that creates an opportunity for learning and development. This is also known as a “Just Culture”</li>
        </ul>
      </>,
    bad:
      <>
        <div className="mt-2">
          Your result indicates that your understanding of how to manage human errors, threats, and risks is good, but create an opportunity to improve these skills further and strengthen your awareness.
        </div>
        <div className="mt-2">
          The following action points will help you to strengthen your competency further:
        </div>

        <ul className="list-disc mt-2 ml-8">
          <li>Remember to always consider the risks and the benefits of taking a certain course of action.</li>
          <li>During periods of low workload, try to think ahead to consider unknown scenarios and identify any threats that could prevent a successful outcome.</li>
          <li>Try to recognise when errors have been made and use your knowledge and procedures to mitigate and manage the effects of them.</li>
          <li>Don’t purposefully break or violate established rules or procedures for personal gain. This may sound obvious, but many people do it and it never leads to a positive outcome.</li>
          <li>It is important to work within a culture that, in the event of human error, promotes open reporting to reach a fair outcome that creates an opportunity for learning and development. This is also known as a “Just Culture”.</li>
        </ul>
      </>,
    ugly:
      <>
        <div className="mt-2">
          Your result indicates that Human Error & Threat Management skills do not come naturally to you. Remember, this does not mean that this is a weakness, it is an opportunity for personal and professional development to enhance your performance. 
        </div>
        <div className="mt-2">
          The following action points will help you to develop this competency:
        </div>
        <ul className="list-disc mt-2 ml-8">
          <li>Remember to always consider the risks and the benefits of taking a certain course of action</li>
          <li>During periods of low workload, try to think ahead to consider unknown scenarios and identify any threats that could prevent a successful outcome.</li>
          <li>Try to recognise when errors have been made and use your knowledge and procedures to mitigate and manage the effects of them</li>
          <li>Don’t purposefully break or violate established rules or procedures for personal gain. This may sound obvious, but many people do it and it never leads to a positive outcome</li>
          <li>It is important to work within a culture that, in the event of Human Error, promotes open reporting to reach a fair outcome that creates an opportunity for learning and development. This is also known as a “Just Culture”</li>
        </ul>
      </>
  },

  pro: {
    opening:
      <>
        <div className="mt-2">
          What does professionalism look like to you? 
        </div>
        <div className="mt-2">
          Do you see your mistakes as an opportunity to learn?
        </div>
      </>,
    quote: "A person’s actions will tell you everything you need to know.",
    quoteCredit: "Joe Mehl",
    preamble:
      <>
        <div className="mt-2">
          A picture paints a thousand words: how would you like to be perceived by others?
        </div>
        <div className="mt-2">
          It can be easy to misunderstand "Professional Standards" as how well you do your job. What it really means is how you apply yourself, how open you are to learning and improving, and how aware you are of your actions and behaviours. 
        </div>
        <div className="mt-2">
          Being empathetic towards others, demonstrating a duty of care to everyone, expressing a motivation for learning and development, and understanding how to manage your emotional reactions, all make up some of the professional aspects of ourselves that we should aspire towards to experience a rewarding and fulfilling career. 
        </div>
        <div className="mt-2">
          Being professional means working collaboratively with your colleagues and understanding the needs of those you are serving.  We want to be role models for self-evaluation and learning. We should be constantly searching for the areas that require improvement and be proactive in making changes to be an even better version of ourselves. 
        </div>
        <div className="mt-2">
          How do we do this? 
        </div>
        <div className="mt-2">
          Self-reflection and self-evaluation. What could I have done differently? Did we perform to our fullest potential today? Have I given myself the opportunity to improve, or did I simply ignore the feedback I was given? 
        </div>
        <div className="mt-2">
          Why stop now when you can be even better than you are already? 
        </div>
      </>,
    good:
      <>
        <div className="mt-2">
          Your results indicate that you have a strong professional awareness and the behaviours associated with this competency may come naturally to you. 
        </div>
        <div className="mt-2">
          Take time to reflect on why these skills may come naturally to you and make sure to continue applying this every day.
        </div>
        <div className="mt-2">
          People with high Professional Standards apply their knowledge and expertise to their work and will manage their working environments to achieve strong performance. 
        </div>
        <ul className="list-disc mt-2 ml-8">
          <li>They will review their personal performance, and the performance of their team, in order to find ways to keep improving.</li>
          <li>People with high standards are proud of their work so maintain high standards of discipline and appearance.</li>
          <li>They demonstrate motivation for professional development and will often consider the outcomes to their actions.</li>
          <li>They are empathetic and maintain a duty of care towards the public, and towards their colleagues</li>
          <li>If they face challenges, difficult situations, or large increases in workload, they will manage their reaction to this which will enable them to think logically and remain professional, rather than behave irrationally. </li>
        </ul>
      </>,
    bad:
      <>
        <div className="mt-2">
          Professional Standards is all about self-awareness and managing yourself within a professional environment. Your results indicates that you have a good professional awareness, but there is potential for your to strengthen your awareness further.
        </div>
        <div className="mt-2">
          The following action points will help you grow and develop professionally:
        </div>

        <ul className="list-disc mt-2 ml-8">
          <li>Don’t be afraid to apply your knowledge and expertise to your work and try to manage your working environments to achieve strong performance.</li>
          <li>Review your personal performance, and the performance of your team, in order to find ways to keep improving.</li>
          <li>Be proud of the work you do and the success you achieve. A sense of pride helps in maintaining high standards of conduct and appearance.</li>
          <li>Be open and motivated to develop professionally.</li>
          <li>Remember to consider the outcomes and consequences of your actions.</li>
          <li>Be empathetic and maintain a duty of care towards the public and towards your colleagues.</li>
          <li>When facing challenges, difficult situations, or large increases in workload, try manage your reaction to this. We call this a “fight or flight” response, so managing this will enable you to think logically and remain professional, rather than behave irrationally. </li>
        </ul>
      </>,
    ugly:
      <>
        <div className="mt-2">
          Professional Standards is all about self-awareness and managing yourself within a professional environment. Your results indicate that these skills may not come naturally to you. This doesn’t mean that you are unprofessional, it simply means there is room for professional development that will enhance your performance further.
        </div>
        <div className="mt-2">
          The following action points will help you to develop this competency:
        </div>

        <ul className="list-disc mt-2 ml-8">
          <li>Don’t be afraid to apply your knowledge and expertise to your work and try to manage your working environments to achieve strong performance.</li>
          <li>Review your personal performance, and the performance of your team, in order to find ways to keep improving.</li>
          <li>Be proud of the work you do and the success you achieve. A sense of pride helps in maintaining high standards of conduct and appearance.</li>
          <li>Be open and motivated to develop professionally.</li>
          <li>Remember to consider the outcomes and consequences of your actions.</li>
          <li>Be empathetic and maintain a duty of care towards the public and towards your colleagues</li>
          <li>When facing challenges, difficult situations, or large increases in workload, try manage your reaction to this. We call this a “fight or flight” response, so managing this will enable you to think logically and remain professional, rather than behave irrationally. </li>
        </ul>
      </>
  }
}

export default reportContent
