import React, { useState, useEffect } from 'react'
import Button from './components/button'
import { Link } from "react-router-dom"

const Profile = () => {
  return (
    <div>
      <div>Demo User</div>
      <div>full name goes here</div>
      <div>change password</div>
      <div>Products</div>
      <div>table of products goes here, which ones you've completed etc with 'results page' + 'download pdf' buttons</div>
      <Link to="/">go back.</Link>
    </div>
  )
}

export default Profile
