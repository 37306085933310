import React, { useState, useEffect, ReactElement, Dispatch, SetStateAction } from 'react'
import ReactDOM from 'react-dom'
import ScenarioView from './questionnaire'
import '../styles.css'
import { sampleAnswers } from '../common/debug'
import Results from './results'
import EntryForm from './entryForm'
import Introduction from './introduction'
import Nav from './nav'
import { ProgressState } from '../common/types'
import Profile from './profile'
import Server from './server'
import { Questionnaire, State } from '../common/model'
import API from '../common/api'
import DisplayPDF from './displayPDF'
import { User } from '../common/api'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import "@fontsource/open-sans";

const beginScenario = (state: State, setState: Dispatch<SetStateAction<State>>) => {
  return () => {
    console.log('begin')
    const newState = Object.assign({}, state)
    newState.startedScenario = true
    setState(newState)
  }
}

const Main = ({questionnaire, user, state, setState, onStartClick, progress}: {questionnaire: Questionnaire, user: User, state: State, setState: Dispatch<SetStateAction<State>>, onStartClick: () => void, progress: ProgressState}) => {
  let currentView: ReactElement = <></>;
  let checkedForResults = false

  useEffect(() => {
    if (user.apiKey.length !== 0 && state.answers.length === 0 && !checkedForResults) {
      Server.getResults(user.id, user.apiKey)
        .then(r => {
          console.log('received results for user')
          if (r.length !== 0) { // we have results
            setState({
              scenario: 0,
              question: 0,
              startedScenario: false,
              stage: 'results',
              answers: r
            })
          }
        }).catch(err => {
          console.error('failed to call getResults endpoint, this should not happen')
          console.error(err)
        })
      checkedForResults = true
    }
  })

  switch(state.stage) {
    case 'introduction':
      currentView = <Introduction onStartClick={onStartClick}/>;
      break;
    case 'questionnaire':
      currentView = <ScenarioView questionnaire={questionnaire} state={state} beginScenario={beginScenario(state, setState)} progress={progress}/>;
      break;
    case 'results':
      currentView = <Results user={user} questionnaire={questionnaire} state={state}/>
        break;
  }
  return (
    <main className="lg:ml-56 p-4">
      <div className="uppercase text-gray-400">Performance Indicator Assessment</div>
      {currentView}
    </main>
  )
}

const App = () => {
  const [questionnaire, setQuestionnaire] = useState({})
  const [auth, setAuth] = useState(false)
  const [user, setUser] = useState({id: -1, email: 'demo@example.com', name: 'Demo User', apiKey: ''})
  const [state, setState] = useState({
    scenario: 0,
    question: 0,
    startedScenario: true,
    stage: 'introduction',
    answers: []
  });

  const start = (state: State, setState: Dispatch<SetStateAction<State>>) => {
    const newState = Object.assign({}, state)
    newState.stage = 'questionnaire'
    newState.scenario = 1
    newState.question = 1
    newState.startedScenario = false
    setState(newState)
  }

  const progress = (questionnaire, user, state, setState) => {
    return (answer, setAnswer) => {
      const newState = Object.assign({}, state)
      const scenario = questionnaire.scenarios[state.scenario-1]
      newState.answers.push(answer)

      if (scenario.questions.length > state.question) {
        newState.question++
        setState(newState)
      } else {
        if (questionnaire.scenarios.length <= state.scenario) {
          Server.saveResults(user.id, user.apiKey, state.answers)
          newState.scenario = 999
          newState.question = 999
          newState.startedScenario = false
          newState.stage = 'results'
          setState(newState)
        } else {
          newState.scenario++
          newState.question = 1
          newState.startedScenario = false
          setState(newState)
        }
      }

      setAnswer('c')
    }
  }

  let content = <EntryForm mode="login" setAuth={setAuth} setUser={setUser}/>

  if (auth) {
    content =
      <div className="fast-fade flex min-h-screen">
        <Nav
          questionnaire={questionnaire}
          state={state}
          setState={setState}
          user={user}
          setUser={setUser}
          setAuth={setAuth}
          completeOnClick={() => (setState({scenario: 999, question: 999, stage: 'results', answers: sampleAnswers}))}
          resetOnClick={() => (setState({scenario: 0, question: 0, stage: 'introduction', answers: []}))}/>
        <Main questionnaire={questionnaire} user={user} state={state} setState={setState} onStartClick={() => (start(state, setState))} beginScenario={() => (beginScenario(state, setState))} progress={() => (progress(questionnaire, user, state, setState))}/>
      </div>
  }

  useEffect(() => {
    if (Object.keys(questionnaire).length === 0) {
      fetch('questionnaire.json')
        .then(response => response.json())
        .then(data => { console.log('questionnaire data loaded'); setQuestionnaire(data) });
    }
  })

  return (
    <Router>
      <Switch>
        <Route path="/sign-up">
          <EntryForm mode="create" setAuth={setAuth} setUser={setUser}/>
        </Route>
        <Route path="/profile">
          <Profile/>
        </Route>
        { /* N.B This should not be user accessible, it's intention is to provide
             wkhtmltopdf with a way to access the view. */}
        <Route path={API.displayPDF}>
          <DisplayPDF/>
        </Route>
        <Route path="/">
          {content}
        </Route>
      </Switch>
    </Router>
  )
}
 
ReactDOM.render(
  <App/>,
  document.getElementById('app')
);
