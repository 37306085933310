import React, { useState, useEffect } from 'react'
import { QuestionBox }  from './questionnaire'
import Button from './components/button'

const Introduction = ({onStartClick}) => {
  const sampleQuestionClick = () => { return (a, as) => { alert('This is an example, clicking this would take you to the next question.'); } } 

  return (
    <div className="mt-3" id='instructions'>
      <div className="w-t">You will be given paragraphs to read that describe different scenarios that you could face in a professional working environment.</div>
      <div className="w-t mt-3">Each question is two statements. These statements outline what <span className="font-bold">YOU</span> would do if this were a real-life scenario. You do not need industry specific knowledge.</div>
      <div className="w-t mt-3 italic font-bold">We ask that you spend no longer than 30 seconds answering each pair of statements and trust your instinctive reaction when answering as this will give the most accurate result for you.</div>
      <div className="w-t mt-3">Below is a sample question box to familiarise yourself with how it works & an explanation of what each circle means:</div>
      <QuestionBox key="sample" labelColor="indigo" descriptionA='Statement A' descriptionB='Statement B' progress={sampleQuestionClick} nextLabel="Next Question" />
      <ul>
        <li className="w-t mt-6"><span className="font-bold">Far Left Circle:</span> you are saying you would most likely action the statement on the left.</li>
        <li className="w-t mt-3"><span className="font-bold">Far Right Circle:</span> you are saying you would most likely action the statement on the right.</li>
        <li className="w-t mt-3"><span className="font-bold">Middle Left Circle:</span> you are saying you would most likely action both statements, however the statement on the left is more important, is more appropriate, or you would action this one first.</li>
        <li className="w-t mt-3"><span className="font-bold">Middle Right Circle:</span> you are saying you would most likely action both statements, however the statement on the right is more important, more appropriate, or you would action this one first.</li>
        <li className="w-t mt-3"><span className="font-bold">Middle Circle:</span> you are saying that both statements are correct and that both statements are equally as important and equally as appropriate.</li>
      </ul>
      <div className="w-t mt-3">Select the circle to choose that answer that applies mostly to you.</div>
      <div className="w-t mt-3">When you are ready, click the button below to begin</div>
      <Button label="Start Assessment" onClick={onStartClick} className="mt-3" />
    </div>
  )
}

export default Introduction
