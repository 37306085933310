import React from 'react'

const Logo = () => {
  const fillColor = "#eee"

  return (
    <div className="-ml-4 mb-12 w-24">
      <svg x="0" y="0" width="200" height="32" viewBox="0, 0, 731.829, 114.5">
        <g id="Layer_1" transform="translate(-59.282, -155.348)">
          <g>
            <g>
              <path d="M791.111,213.515 L155.35,215.546 L59.282,213.484 L155.35,211.515 z" fill={fillColor}/>
              <path d="M155.617,171.281 C179.089,171.281 198.117,190.309 198.117,213.781 C198.117,237.253 179.089,256.281 155.617,256.281 C132.145,256.281 113.117,237.253 113.117,213.781 C113.117,190.309 132.145,171.281 155.617,171.281 z M155.617,172.281 C132.697,172.281 114.117,190.861 114.117,213.781 C114.117,236.701 132.697,255.281 155.617,255.281 C178.536,255.281 197.117,236.701 197.117,213.781 C197.117,190.861 178.536,172.281 155.617,172.281 z" fill={fillColor}/>
              <path d="M155.347,155.348 L157.882,217.551 L155.308,269.848 L152.851,217.551 z" fill={fillColor}/>
              <path d="M115.872,253.057 L157.073,210.749 L179.207,190.048 L158.369,212.052 z" fill={fillColor}/>
              <path d="M189.072,247.184 L145.4,204.483 L123.842,181.738 L146.498,203.393 z" fill={fillColor}/>
            </g>
            <g>
              <path d="M212.5,232.96 L212.5,225.373 L219.358,225.373 Q221.464,225.373 222.49,226.399 Q223.516,227.425 223.516,229.207 Q223.516,230.098 223.178,230.787 Q222.841,231.475 222.274,231.961 Q221.707,232.447 220.951,232.704 Q220.195,232.96 219.358,232.96 z M210.664,223.807 L210.664,243.085 L212.5,243.085 L212.5,234.526 L219.466,234.526 Q220.762,234.472 221.504,234.958 Q222.247,235.444 222.638,236.241 Q223.03,237.037 223.151,238.05 Q223.273,239.062 223.327,240.088 Q223.354,240.385 223.381,240.79 Q223.408,241.195 223.448,241.614 Q223.489,242.032 223.583,242.424 Q223.678,242.815 223.84,243.085 L225.865,243.085 Q225.568,242.734 225.392,242.1 Q225.217,241.465 225.136,240.75 Q225.055,240.034 225.028,239.332 Q225.001,238.63 224.974,238.144 Q224.92,237.28 224.744,236.538 Q224.569,235.795 224.177,235.228 Q223.786,234.661 223.165,234.297 Q222.544,233.932 221.599,233.797 L221.599,233.743 Q223.462,233.365 224.407,232.015 Q225.352,230.665 225.352,228.829 Q225.352,227.533 224.879,226.575 Q224.407,225.616 223.61,225.009 Q222.814,224.401 221.747,224.104 Q220.681,223.807 219.493,223.807 z" fill={fillColor}/>
              <path d="M239.366,235.147 L230.348,235.147 Q230.456,234.202 230.793,233.298 Q231.131,232.393 231.698,231.705 Q232.265,231.016 233.075,230.598 Q233.885,230.179 234.938,230.179 Q235.964,230.179 236.774,230.598 Q237.584,231.016 238.151,231.705 Q238.718,232.393 239.028,233.284 Q239.339,234.175 239.366,235.147 z M230.348,236.578 L241.067,236.578 Q241.121,235.12 240.797,233.703 Q240.473,232.285 239.73,231.192 Q238.988,230.098 237.8,229.423 Q236.612,228.748 234.938,228.748 Q233.291,228.748 232.089,229.383 Q230.888,230.017 230.132,231.057 Q229.376,232.096 229.011,233.419 Q228.647,234.742 228.647,236.119 Q228.647,237.604 229.011,238.941 Q229.376,240.277 230.132,241.29 Q230.888,242.302 232.089,242.883 Q233.291,243.463 234.938,243.463 Q237.584,243.463 239.028,242.167 Q240.473,240.871 241.013,238.495 L239.312,238.495 Q238.907,240.115 237.854,241.087 Q236.801,242.059 234.938,242.059 Q233.723,242.059 232.859,241.546 Q231.995,241.033 231.428,240.25 Q230.861,239.467 230.604,238.482 Q230.348,237.496 230.348,236.578 z" fill={fillColor}/>
              <path d="M244.2,233.419 L245.901,233.419 Q245.955,231.718 246.981,230.949 Q248.007,230.179 249.627,230.179 Q250.383,230.179 251.044,230.301 Q251.706,230.422 252.205,230.746 Q252.705,231.07 252.988,231.624 Q253.272,232.177 253.272,233.041 Q253.272,233.608 253.123,233.959 Q252.975,234.31 252.705,234.513 Q252.435,234.715 252.03,234.796 Q251.625,234.877 251.085,234.931 Q249.708,235.093 248.371,235.269 Q247.035,235.444 245.968,235.876 Q244.902,236.308 244.254,237.132 Q243.606,237.955 243.606,239.413 Q243.606,240.466 243.97,241.236 Q244.335,242.005 244.969,242.505 Q245.604,243.004 246.454,243.234 Q247.305,243.463 248.277,243.463 Q249.303,243.463 250.045,243.274 Q250.788,243.085 251.368,242.734 Q251.949,242.383 252.421,241.857 Q252.894,241.33 253.353,240.655 L253.407,240.655 Q253.407,241.195 253.474,241.641 Q253.542,242.086 253.758,242.41 Q253.974,242.734 254.365,242.91 Q254.757,243.085 255.405,243.085 Q255.756,243.085 255.985,243.058 Q256.215,243.031 256.512,242.977 L256.512,241.546 Q256.269,241.654 255.972,241.654 Q254.973,241.654 254.973,240.628 L254.973,233.23 Q254.973,231.826 254.514,230.949 Q254.055,230.071 253.326,229.585 Q252.597,229.099 251.679,228.924 Q250.761,228.748 249.843,228.748 Q248.628,228.748 247.629,229.018 Q246.63,229.288 245.887,229.855 Q245.145,230.422 244.713,231.313 Q244.281,232.204 244.2,233.419 z M253.272,235.471 L253.272,237.685 Q253.272,238.711 252.88,239.521 Q252.489,240.331 251.827,240.898 Q251.166,241.465 250.275,241.762 Q249.384,242.059 248.412,242.059 Q247.818,242.059 247.251,241.87 Q246.684,241.681 246.252,241.317 Q245.82,240.952 245.563,240.453 Q245.307,239.953 245.307,239.305 Q245.307,238.279 245.914,237.726 Q246.522,237.172 247.467,236.875 Q248.412,236.578 249.559,236.43 Q250.707,236.281 251.787,236.092 Q252.138,236.038 252.597,235.903 Q253.056,235.768 253.218,235.471 z" fill={fillColor}/>
              <path d="M259.402,223.807 L259.402,243.085 L261.103,243.085 L261.103,223.807 z" fill={fillColor}/>
              <path d="M265.397,223.807 L265.397,226.534 L267.098,226.534 L267.098,223.807 z M265.397,229.153 L265.397,243.085 L267.098,243.085 L267.098,229.153 z" fill={fillColor}/>
              <path d="M279.708,233.23 L281.409,233.23 Q281.355,232.096 280.95,231.259 Q280.545,230.422 279.856,229.855 Q279.168,229.288 278.263,229.018 Q277.359,228.748 276.306,228.748 Q275.388,228.748 274.456,228.964 Q273.525,229.18 272.769,229.639 Q272.013,230.098 271.54,230.854 Q271.068,231.61 271.068,232.663 Q271.068,233.554 271.365,234.162 Q271.662,234.769 272.188,235.188 Q272.715,235.606 273.417,235.89 Q274.119,236.173 274.956,236.389 L277.143,236.875 Q277.71,237.01 278.263,237.199 Q278.817,237.388 279.249,237.672 Q279.681,237.955 279.937,238.374 Q280.194,238.792 280.194,239.413 Q280.194,240.142 279.829,240.642 Q279.465,241.141 278.898,241.465 Q278.331,241.789 277.669,241.924 Q277.008,242.059 276.387,242.059 Q274.686,242.059 273.538,241.182 Q272.391,240.304 272.283,238.576 L270.582,238.576 Q270.798,241.141 272.323,242.302 Q273.849,243.463 276.306,243.463 Q277.278,243.463 278.277,243.247 Q279.276,243.031 280.072,242.532 Q280.869,242.032 281.382,241.236 Q281.895,240.439 281.895,239.305 Q281.895,238.387 281.544,237.712 Q281.193,237.037 280.639,236.578 Q280.086,236.119 279.37,235.836 Q278.655,235.552 277.926,235.444 L275.658,234.931 Q275.226,234.823 274.713,234.648 Q274.2,234.472 273.768,234.202 Q273.336,233.932 273.052,233.541 Q272.769,233.149 272.769,232.582 Q272.769,231.907 273.066,231.448 Q273.363,230.989 273.849,230.706 Q274.335,230.422 274.915,230.301 Q275.496,230.179 276.063,230.179 Q276.792,230.179 277.44,230.355 Q278.088,230.53 278.587,230.908 Q279.087,231.286 279.384,231.867 Q279.681,232.447 279.708,233.23 z" fill={fillColor}/>
              <path d="M295.369,235.147 L286.351,235.147 Q286.459,234.202 286.796,233.298 Q287.134,232.393 287.701,231.705 Q288.268,231.016 289.078,230.598 Q289.888,230.179 290.941,230.179 Q291.967,230.179 292.777,230.598 Q293.587,231.016 294.154,231.705 Q294.721,232.393 295.031,233.284 Q295.342,234.175 295.369,235.147 z M286.351,236.578 L297.07,236.578 Q297.124,235.12 296.8,233.703 Q296.476,232.285 295.733,231.192 Q294.991,230.098 293.803,229.423 Q292.615,228.748 290.941,228.748 Q289.294,228.748 288.092,229.383 Q286.891,230.017 286.135,231.057 Q285.379,232.096 285.014,233.419 Q284.65,234.742 284.65,236.119 Q284.65,237.604 285.014,238.941 Q285.379,240.277 286.135,241.29 Q286.891,242.302 288.092,242.883 Q289.294,243.463 290.941,243.463 Q293.587,243.463 295.031,242.167 Q296.476,240.871 297.016,238.495 L295.315,238.495 Q294.91,240.115 293.857,241.087 Q292.804,242.059 290.941,242.059 Q289.726,242.059 288.862,241.546 Q287.998,241.033 287.431,240.25 Q286.864,239.467 286.607,238.482 Q286.351,237.496 286.351,236.578 z" fill={fillColor}/>
              <path d="M307.305,229.153 L312.867,243.031 L312.273,244.597 Q312.057,245.083 311.868,245.488 Q311.679,245.893 311.409,246.177 Q311.139,246.46 310.774,246.622 Q310.41,246.784 309.843,246.784 Q309.546,246.784 309.262,246.744 Q308.979,246.703 308.682,246.649 L308.682,248.08 Q308.898,248.161 309.181,248.175 Q309.465,248.188 309.978,248.215 Q310.788,248.215 311.314,248.067 Q311.841,247.918 312.232,247.581 Q312.624,247.243 312.948,246.663 Q313.272,246.082 313.65,245.191 L319.698,229.153 L317.997,229.153 L313.704,241.033 L309.114,229.153 z" fill={fillColor}/>
              <path d="M328.258,230.179 Q329.473,230.179 330.391,230.692 Q331.309,231.205 331.916,232.042 Q332.524,232.879 332.834,233.946 Q333.145,235.012 333.145,236.119 Q333.145,237.226 332.834,238.293 Q332.524,239.359 331.916,240.196 Q331.309,241.033 330.391,241.546 Q329.473,242.059 328.258,242.059 Q327.043,242.059 326.125,241.546 Q325.207,241.033 324.599,240.196 Q323.992,239.359 323.681,238.293 Q323.371,237.226 323.371,236.119 Q323.371,235.012 323.681,233.946 Q323.992,232.879 324.599,232.042 Q325.207,231.205 326.125,230.692 Q327.043,230.179 328.258,230.179 z M328.258,228.748 Q326.665,228.748 325.436,229.342 Q324.208,229.936 323.371,230.935 Q322.534,231.934 322.102,233.271 Q321.67,234.607 321.67,236.119 Q321.67,237.631 322.102,238.968 Q322.534,240.304 323.371,241.303 Q324.208,242.302 325.436,242.883 Q326.665,243.463 328.258,243.463 Q329.851,243.463 331.079,242.883 Q332.308,242.302 333.145,241.303 Q333.982,240.304 334.414,238.968 Q334.846,237.631 334.846,236.119 Q334.846,234.607 334.414,233.271 Q333.982,231.934 333.145,230.935 Q332.308,229.936 331.079,229.342 Q329.851,228.748 328.258,228.748 z" fill={fillColor}/>
              <path d="M349.67,243.085 L349.67,229.153 L347.969,229.153 L347.969,236.47 Q347.969,237.523 347.726,238.536 Q347.483,239.548 346.97,240.331 Q346.457,241.114 345.674,241.587 Q344.891,242.059 343.784,242.059 Q341.759,242.059 340.935,241.087 Q340.112,240.115 340.058,238.225 L340.058,229.153 L338.357,229.153 L338.357,238.198 Q338.357,239.44 338.627,240.412 Q338.897,241.384 339.477,242.059 Q340.058,242.734 340.989,243.099 Q341.921,243.463 343.244,243.463 Q344.81,243.463 346.092,242.721 Q347.375,241.978 348.05,240.574 L348.104,240.574 L348.104,243.085 z" fill={fillColor}/>
              <path d="M353.883,229.153 L353.883,243.085 L355.584,243.085 L355.584,235.66 Q355.584,234.553 355.962,233.622 Q356.34,232.69 357.028,232.015 Q357.717,231.34 358.662,230.976 Q359.607,230.611 360.768,230.665 L360.768,228.964 Q358.878,228.883 357.514,229.801 Q356.151,230.719 355.503,232.42 L355.449,232.42 L355.449,229.153 z" fill={fillColor}/>
              <path d="M371.894,229.153 L371.894,248.215 L373.595,248.215 L373.595,240.439 L373.649,240.439 Q373.919,241.195 374.418,241.762 Q374.918,242.329 375.566,242.707 Q376.214,243.085 376.97,243.274 Q377.726,243.463 378.482,243.463 Q380.075,243.463 381.249,242.883 Q382.424,242.302 383.207,241.29 Q383.99,240.277 384.368,238.941 Q384.746,237.604 384.746,236.119 Q384.746,234.634 384.368,233.298 Q383.99,231.961 383.207,230.949 Q382.424,229.936 381.249,229.342 Q380.075,228.748 378.482,228.748 Q376.781,228.748 375.444,229.545 Q374.108,230.341 373.514,231.799 L373.46,231.799 L373.46,229.153 z M383.045,236.119 Q383.045,237.199 382.788,238.266 Q382.532,239.332 381.978,240.169 Q381.425,241.006 380.561,241.533 Q379.697,242.059 378.482,242.059 Q377.132,242.059 376.2,241.533 Q375.269,241.006 374.688,240.169 Q374.108,239.332 373.851,238.266 Q373.595,237.199 373.595,236.119 Q373.595,234.931 373.824,233.851 Q374.054,232.771 374.621,231.961 Q375.188,231.151 376.133,230.665 Q377.078,230.179 378.482,230.179 Q379.697,230.179 380.561,230.706 Q381.425,231.232 381.978,232.069 Q382.532,232.906 382.788,233.973 Q383.045,235.039 383.045,236.119 z" fill={fillColor}/>
              <path d="M394.278,230.179 Q395.493,230.179 396.411,230.692 Q397.329,231.205 397.936,232.042 Q398.544,232.879 398.854,233.946 Q399.165,235.012 399.165,236.119 Q399.165,237.226 398.854,238.293 Q398.544,239.359 397.936,240.196 Q397.329,241.033 396.411,241.546 Q395.493,242.059 394.278,242.059 Q393.063,242.059 392.145,241.546 Q391.227,241.033 390.619,240.196 Q390.012,239.359 389.701,238.293 Q389.391,237.226 389.391,236.119 Q389.391,235.012 389.701,233.946 Q390.012,232.879 390.619,232.042 Q391.227,231.205 392.145,230.692 Q393.063,230.179 394.278,230.179 z M394.278,228.748 Q392.685,228.748 391.456,229.342 Q390.228,229.936 389.391,230.935 Q388.554,231.934 388.122,233.271 Q387.69,234.607 387.69,236.119 Q387.69,237.631 388.122,238.968 Q388.554,240.304 389.391,241.303 Q390.228,242.302 391.456,242.883 Q392.685,243.463 394.278,243.463 Q395.871,243.463 397.099,242.883 Q398.328,242.302 399.165,241.303 Q400.002,240.304 400.434,238.968 Q400.866,237.631 400.866,236.119 Q400.866,234.607 400.434,233.271 Q400.002,231.934 399.165,230.935 Q398.328,229.936 397.099,229.342 Q395.871,228.748 394.278,228.748 z" fill={fillColor}/>
              <path d="M407.104,229.153 L407.104,224.968 L405.403,224.968 L405.403,229.153 L402.973,229.153 L402.973,230.584 L405.403,230.584 L405.403,240.115 Q405.376,241.897 406.051,242.559 Q406.726,243.22 408.427,243.22 Q408.805,243.22 409.183,243.193 Q409.561,243.166 409.939,243.166 L409.939,241.735 Q409.21,241.816 408.481,241.816 Q407.563,241.762 407.333,241.29 Q407.104,240.817 407.104,239.98 L407.104,230.584 L409.939,230.584 L409.939,229.153 z" fill={fillColor}/>
              <path d="M423.413,235.147 L414.395,235.147 Q414.503,234.202 414.84,233.298 Q415.178,232.393 415.745,231.705 Q416.312,231.016 417.122,230.598 Q417.932,230.179 418.985,230.179 Q420.011,230.179 420.821,230.598 Q421.631,231.016 422.198,231.705 Q422.765,232.393 423.075,233.284 Q423.386,234.175 423.413,235.147 z M414.395,236.578 L425.114,236.578 Q425.168,235.12 424.844,233.703 Q424.52,232.285 423.777,231.192 Q423.035,230.098 421.847,229.423 Q420.659,228.748 418.985,228.748 Q417.338,228.748 416.136,229.383 Q414.935,230.017 414.179,231.057 Q413.423,232.096 413.058,233.419 Q412.694,234.742 412.694,236.119 Q412.694,237.604 413.058,238.941 Q413.423,240.277 414.179,241.29 Q414.935,242.302 416.136,242.883 Q417.338,243.463 418.985,243.463 Q421.631,243.463 423.075,242.167 Q424.52,240.871 425.06,238.495 L423.359,238.495 Q422.954,240.115 421.901,241.087 Q420.848,242.059 418.985,242.059 Q417.77,242.059 416.906,241.546 Q416.042,241.033 415.475,240.25 Q414.908,239.467 414.651,238.482 Q414.395,237.496 414.395,236.578 z" fill={fillColor}/>
              <path d="M428.382,229.153 L428.382,243.085 L430.083,243.085 L430.083,234.958 Q430.11,233.932 430.42,233.055 Q430.731,232.177 431.284,231.543 Q431.838,230.908 432.634,230.544 Q433.431,230.179 434.457,230.179 Q435.483,230.179 436.171,230.503 Q436.86,230.827 437.265,231.394 Q437.67,231.961 437.832,232.731 Q437.994,233.5 437.994,234.391 L437.994,243.085 L439.695,243.085 L439.695,234.121 Q439.695,232.879 439.452,231.88 Q439.209,230.881 438.615,230.193 Q438.021,229.504 437.035,229.126 Q436.05,228.748 434.592,228.748 Q433.107,228.748 431.865,229.518 Q430.623,230.287 430.137,231.556 L430.083,231.556 L430.083,229.153 z" fill={fillColor}/>
              <path d="M446.608,229.153 L446.608,224.968 L444.907,224.968 L444.907,229.153 L442.477,229.153 L442.477,230.584 L444.907,230.584 L444.907,240.115 Q444.88,241.897 445.555,242.559 Q446.23,243.22 447.931,243.22 Q448.309,243.22 448.687,243.193 Q449.065,243.166 449.443,243.166 L449.443,241.735 Q448.714,241.816 447.985,241.816 Q447.067,241.762 446.837,241.29 Q446.608,240.817 446.608,239.98 L446.608,230.584 L449.443,230.584 L449.443,229.153 z" fill={fillColor}/>
              <path d="M452.927,223.807 L452.927,226.534 L454.628,226.534 L454.628,223.807 z M452.927,229.153 L452.927,243.085 L454.628,243.085 L454.628,229.153 z" fill={fillColor}/>
              <path d="M458.733,233.419 L460.434,233.419 Q460.488,231.718 461.514,230.949 Q462.54,230.179 464.16,230.179 Q464.916,230.179 465.577,230.301 Q466.239,230.422 466.738,230.746 Q467.238,231.07 467.521,231.624 Q467.805,232.177 467.805,233.041 Q467.805,233.608 467.656,233.959 Q467.508,234.31 467.238,234.513 Q466.968,234.715 466.563,234.796 Q466.158,234.877 465.618,234.931 Q464.241,235.093 462.904,235.269 Q461.568,235.444 460.501,235.876 Q459.435,236.308 458.787,237.132 Q458.139,237.955 458.139,239.413 Q458.139,240.466 458.503,241.236 Q458.868,242.005 459.502,242.505 Q460.137,243.004 460.987,243.234 Q461.838,243.463 462.81,243.463 Q463.836,243.463 464.578,243.274 Q465.321,243.085 465.901,242.734 Q466.482,242.383 466.954,241.857 Q467.427,241.33 467.886,240.655 L467.94,240.655 Q467.94,241.195 468.007,241.641 Q468.075,242.086 468.291,242.41 Q468.507,242.734 468.898,242.91 Q469.29,243.085 469.938,243.085 Q470.289,243.085 470.518,243.058 Q470.748,243.031 471.045,242.977 L471.045,241.546 Q470.802,241.654 470.505,241.654 Q469.506,241.654 469.506,240.628 L469.506,233.23 Q469.506,231.826 469.047,230.949 Q468.588,230.071 467.859,229.585 Q467.13,229.099 466.212,228.924 Q465.294,228.748 464.376,228.748 Q463.161,228.748 462.162,229.018 Q461.163,229.288 460.42,229.855 Q459.678,230.422 459.246,231.313 Q458.814,232.204 458.733,233.419 z M467.805,235.471 L467.805,237.685 Q467.805,238.711 467.413,239.521 Q467.022,240.331 466.36,240.898 Q465.699,241.465 464.808,241.762 Q463.917,242.059 462.945,242.059 Q462.351,242.059 461.784,241.87 Q461.217,241.681 460.785,241.317 Q460.353,240.952 460.096,240.453 Q459.84,239.953 459.84,239.305 Q459.84,238.279 460.447,237.726 Q461.055,237.172 462,236.875 Q462.945,236.578 464.092,236.43 Q465.24,236.281 466.32,236.092 Q466.671,236.038 467.13,235.903 Q467.589,235.768 467.751,235.471 z" fill={fillColor}/>
              <path d="M473.935,223.807 L473.935,243.085 L475.636,243.085 L475.636,223.807 z" fill={fillColor}/>
            </g>
            <g>
              <path d="M215.599,193.571 L219.889,182.591 L224.029,193.571 z M218.839,180.551 L210.229,201.971 L212.389,201.971 L214.969,195.311 L224.719,195.311 L227.329,201.971 L229.519,201.971 L221.119,180.551 z" fill={fillColor}/>
              <path d="M231.469,180.551 L231.469,201.971 L238.879,201.971 Q243.769,201.851 246.244,199.226 Q248.719,196.601 248.719,191.261 Q248.719,185.921 246.244,183.296 Q243.769,180.671 238.879,180.551 z M233.509,200.231 L233.509,182.291 L237.859,182.291 Q240.169,182.291 241.834,182.786 Q243.499,183.281 244.579,184.361 Q245.659,185.441 246.169,187.151 Q246.679,188.861 246.679,191.261 Q246.679,193.661 246.169,195.371 Q245.659,197.081 244.579,198.161 Q243.499,199.241 241.834,199.736 Q240.169,200.231 237.859,200.231 z" fill={fillColor}/>
              <path d="M263.389,193.571 L267.679,182.591 L271.819,193.571 z M266.629,180.551 L258.019,201.971 L260.179,201.971 L262.759,195.311 L272.509,195.311 L275.119,201.971 L277.309,201.971 L268.909,180.551 z" fill={fillColor}/>
              <path d="M280.219,194.921 L278.179,194.921 Q278.089,196.901 278.704,198.311 Q279.319,199.721 280.459,200.621 Q281.599,201.521 283.219,201.956 Q284.839,202.391 286.729,202.391 Q288.619,202.391 289.969,202.046 Q291.319,201.701 292.234,201.146 Q293.149,200.591 293.674,199.901 Q294.199,199.211 294.484,198.536 Q294.769,197.861 294.844,197.261 Q294.919,196.661 294.919,196.301 Q294.919,194.981 294.484,194.051 Q294.049,193.121 293.284,192.476 Q292.519,191.831 291.514,191.396 Q290.509,190.961 289.369,190.661 L284.149,189.371 Q283.489,189.221 282.904,188.981 Q282.319,188.741 281.869,188.351 Q281.419,187.961 281.164,187.406 Q280.909,186.851 280.909,186.101 Q280.909,184.901 281.359,184.091 Q281.809,183.281 282.544,182.786 Q283.279,182.291 284.254,182.066 Q285.229,181.841 286.279,181.841 Q287.419,181.841 288.454,182.156 Q289.489,182.471 290.284,183.086 Q291.079,183.701 291.574,184.616 Q292.069,185.531 292.129,186.761 L294.169,186.761 Q294.169,185.111 293.524,183.866 Q292.879,182.621 291.799,181.781 Q290.719,180.941 289.279,180.521 Q287.839,180.101 286.249,180.101 Q283.999,180.101 282.559,180.746 Q281.119,181.391 280.294,182.321 Q279.469,183.251 279.169,184.301 Q278.869,185.351 278.869,186.131 Q278.869,187.391 279.274,188.261 Q279.679,189.131 280.339,189.731 Q280.999,190.331 281.884,190.691 Q282.769,191.051 283.699,191.291 L288.469,192.461 Q289.219,192.641 289.999,192.926 Q290.779,193.211 291.424,193.661 Q292.069,194.111 292.474,194.771 Q292.879,195.431 292.879,196.331 Q292.879,197.501 292.309,198.326 Q291.739,199.151 290.884,199.676 Q290.029,200.201 289.039,200.441 Q288.049,200.681 287.209,200.681 Q285.739,200.681 284.449,200.396 Q283.159,200.111 282.214,199.451 Q281.269,198.791 280.729,197.681 Q280.189,196.571 280.219,194.921 z" fill={fillColor}/>
              <path d="M295.879,180.551 L295.879,182.291 L303.319,182.291 L303.319,201.971 L305.359,201.971 L305.359,182.291 L312.829,182.291 L312.829,180.551 z" fill={fillColor}/>
              <path d="M316.879,190.721 L316.879,182.291 L324.499,182.291 Q326.839,182.291 327.979,183.431 Q329.119,184.571 329.119,186.551 Q329.119,187.541 328.744,188.306 Q328.369,189.071 327.739,189.611 Q327.109,190.151 326.269,190.436 Q325.429,190.721 324.499,190.721 z M314.839,180.551 L314.839,201.971 L316.879,201.971 L316.879,192.461 L324.619,192.461 Q326.059,192.401 326.884,192.941 Q327.709,193.481 328.144,194.366 Q328.579,195.251 328.714,196.376 Q328.849,197.501 328.909,198.641 Q328.939,198.971 328.969,199.421 Q328.999,199.871 329.044,200.336 Q329.089,200.801 329.194,201.236 Q329.299,201.671 329.479,201.971 L331.729,201.971 Q331.399,201.581 331.204,200.876 Q331.009,200.171 330.919,199.376 Q330.829,198.581 330.799,197.801 Q330.769,197.021 330.739,196.481 Q330.679,195.521 330.484,194.696 Q330.289,193.871 329.854,193.241 Q329.419,192.611 328.729,192.206 Q328.039,191.801 326.989,191.651 L326.989,191.591 Q329.059,191.171 330.109,189.671 Q331.159,188.171 331.159,186.131 Q331.159,184.691 330.634,183.626 Q330.109,182.561 329.224,181.886 Q328.339,181.211 327.154,180.881 Q325.969,180.551 324.649,180.551 z" fill={fillColor}/>
              <path d="M337.879,193.571 L342.169,182.591 L346.309,193.571 z M341.119,180.551 L332.509,201.971 L334.669,201.971 L337.249,195.311 L346.999,195.311 L349.609,201.971 L351.799,201.971 L343.399,180.551 z" fill={fillColor}/>
              <path d="M362.089,180.551 L362.089,201.971 L364.129,201.971 L364.129,191.591 L376.849,191.591 L376.849,201.971 L378.889,201.971 L378.889,180.551 L376.849,180.551 L376.849,189.851 L364.129,189.851 L364.129,180.551 z" fill={fillColor}/>
              <path d="M382.999,180.551 L382.999,194.261 Q382.999,195.911 383.464,197.396 Q383.929,198.881 384.934,199.991 Q385.939,201.101 387.514,201.746 Q389.089,202.391 391.309,202.391 Q393.559,202.391 395.134,201.746 Q396.709,201.101 397.714,199.991 Q398.719,198.881 399.184,197.396 Q399.649,195.911 399.649,194.261 L399.649,180.551 L397.609,180.551 L397.609,193.811 Q397.609,195.671 397.189,196.976 Q396.769,198.281 395.959,199.106 Q395.149,199.931 393.979,200.306 Q392.809,200.681 391.309,200.681 Q389.839,200.681 388.669,200.306 Q387.499,199.931 386.689,199.106 Q385.879,198.281 385.459,196.976 Q385.039,195.671 385.039,193.811 L385.039,180.551 z" fill={fillColor}/>
              <path d="M406.699,180.551 L413.809,199.331 L421.189,180.551 L424.189,180.551 L424.189,201.971 L422.149,201.971 L422.149,183.461 L422.089,183.461 L414.769,201.971 L412.849,201.971 L405.799,183.461 L405.739,183.461 L405.739,201.971 L403.699,201.971 L403.699,180.551 z" fill={fillColor}/>
              <path d="M431.419,193.571 L435.709,182.591 L439.849,193.571 z M434.659,180.551 L426.049,201.971 L428.209,201.971 L430.789,195.311 L440.539,195.311 L443.149,201.971 L445.339,201.971 L436.939,180.551 z" fill={fillColor}/>
              <path d="M447.259,180.551 L447.259,201.971 L449.299,201.971 L449.299,183.851 L449.359,183.851 L461.839,201.971 L464.119,201.971 L464.119,180.551 L462.079,180.551 L462.079,198.671 L462.019,198.671 L449.539,180.551 z" fill={fillColor}/>
              <path d="M478.789,191.051 L478.789,182.291 L485.809,182.291 Q487.939,182.291 489.259,183.371 Q490.579,184.451 490.579,186.671 Q490.579,188.891 489.259,189.971 Q487.939,191.051 485.809,191.051 z M476.749,180.551 L476.749,201.971 L478.789,201.971 L478.789,192.791 L486.259,192.791 Q487.699,192.791 488.869,192.386 Q490.039,191.981 490.879,191.186 Q491.719,190.391 492.169,189.251 Q492.619,188.111 492.619,186.671 Q492.619,185.231 492.169,184.091 Q491.719,182.951 490.879,182.156 Q490.039,181.361 488.869,180.956 Q487.699,180.551 486.259,180.551 z" fill={fillColor}/>
              <path d="M495.649,180.551 L495.649,201.971 L510.589,201.971 L510.589,200.231 L497.689,200.231 L497.689,191.771 L509.629,191.771 L509.629,190.031 L497.689,190.031 L497.689,182.291 L510.439,182.291 L510.439,180.551 z" fill={fillColor}/>
              <path d="M515.479,190.721 L515.479,182.291 L523.099,182.291 Q525.439,182.291 526.579,183.431 Q527.719,184.571 527.719,186.551 Q527.719,187.541 527.344,188.306 Q526.969,189.071 526.339,189.611 Q525.709,190.151 524.869,190.436 Q524.029,190.721 523.099,190.721 z M513.439,180.551 L513.439,201.971 L515.479,201.971 L515.479,192.461 L523.219,192.461 Q524.659,192.401 525.484,192.941 Q526.309,193.481 526.744,194.366 Q527.179,195.251 527.314,196.376 Q527.449,197.501 527.509,198.641 Q527.539,198.971 527.569,199.421 Q527.599,199.871 527.644,200.336 Q527.689,200.801 527.794,201.236 Q527.899,201.671 528.079,201.971 L530.329,201.971 Q529.999,201.581 529.804,200.876 Q529.609,200.171 529.519,199.376 Q529.429,198.581 529.399,197.801 Q529.369,197.021 529.339,196.481 Q529.279,195.521 529.084,194.696 Q528.889,193.871 528.454,193.241 Q528.019,192.611 527.329,192.206 Q526.639,191.801 525.589,191.651 L525.589,191.591 Q527.659,191.171 528.709,189.671 Q529.759,188.171 529.759,186.131 Q529.759,184.691 529.234,183.626 Q528.709,182.561 527.824,181.886 Q526.939,181.211 525.754,180.881 Q524.569,180.551 523.249,180.551 z" fill={fillColor}/>
              <path d="M533.449,180.551 L533.449,201.971 L535.489,201.971 L535.489,191.771 L545.749,191.771 L545.749,190.031 L535.489,190.031 L535.489,182.291 L547.039,182.291 L547.039,180.551 z" fill={fillColor}/>
              <path d="M550.459,191.261 Q550.459,189.491 550.924,187.811 Q551.389,186.131 552.364,184.796 Q553.339,183.461 554.869,182.651 Q556.399,181.841 558.529,181.841 Q560.659,181.841 562.174,182.651 Q563.689,183.461 564.664,184.796 Q565.639,186.131 566.104,187.811 Q566.569,189.491 566.569,191.261 Q566.569,193.001 566.104,194.696 Q565.639,196.391 564.664,197.726 Q563.689,199.061 562.174,199.871 Q560.659,200.681 558.529,200.681 Q556.399,200.681 554.869,199.871 Q553.339,199.061 552.364,197.726 Q551.389,196.391 550.924,194.696 Q550.459,193.001 550.459,191.261 z M548.419,191.261 Q548.419,193.481 549.049,195.491 Q549.679,197.501 550.939,199.031 Q552.199,200.561 554.104,201.476 Q556.009,202.391 558.529,202.391 Q561.049,202.391 562.939,201.476 Q564.829,200.561 566.089,199.031 Q567.349,197.501 567.979,195.491 Q568.609,193.481 568.609,191.261 Q568.609,189.041 567.979,187.031 Q567.349,185.021 566.089,183.476 Q564.829,181.931 562.939,181.016 Q561.049,180.101 558.529,180.101 Q556.009,180.101 554.104,181.016 Q552.199,181.931 550.939,183.476 Q549.679,185.021 549.049,187.031 Q548.419,189.041 548.419,191.261 z" fill={fillColor}/>
              <path d="M573.829,190.721 L573.829,182.291 L581.449,182.291 Q583.789,182.291 584.929,183.431 Q586.069,184.571 586.069,186.551 Q586.069,187.541 585.694,188.306 Q585.319,189.071 584.689,189.611 Q584.059,190.151 583.219,190.436 Q582.379,190.721 581.449,190.721 z M571.789,180.551 L571.789,201.971 L573.829,201.971 L573.829,192.461 L581.569,192.461 Q583.009,192.401 583.834,192.941 Q584.659,193.481 585.094,194.366 Q585.529,195.251 585.664,196.376 Q585.799,197.501 585.859,198.641 Q585.889,198.971 585.919,199.421 Q585.949,199.871 585.994,200.336 Q586.039,200.801 586.144,201.236 Q586.249,201.671 586.429,201.971 L588.679,201.971 Q588.349,201.581 588.154,200.876 Q587.959,200.171 587.869,199.376 Q587.779,198.581 587.749,197.801 Q587.719,197.021 587.689,196.481 Q587.629,195.521 587.434,194.696 Q587.239,193.871 586.804,193.241 Q586.369,192.611 585.679,192.206 Q584.989,191.801 583.939,191.651 L583.939,191.591 Q586.009,191.171 587.059,189.671 Q588.109,188.171 588.109,186.131 Q588.109,184.691 587.584,183.626 Q587.059,182.561 586.174,181.886 Q585.289,181.211 584.104,180.881 Q582.919,180.551 581.599,180.551 z" fill={fillColor}/>
              <path d="M594.739,180.551 L601.849,199.331 L609.229,180.551 L612.229,180.551 L612.229,201.971 L610.189,201.971 L610.189,183.461 L610.129,183.461 L602.809,201.971 L600.889,201.971 L593.839,183.461 L593.779,183.461 L593.779,201.971 L591.739,201.971 L591.739,180.551 z" fill={fillColor}/>
              <path d="M619.459,193.571 L623.749,182.591 L627.889,193.571 z M622.699,180.551 L614.089,201.971 L616.249,201.971 L618.829,195.311 L628.579,195.311 L631.189,201.971 L633.379,201.971 L624.979,180.551 z" fill={fillColor}/>
              <path d="M635.299,180.551 L635.299,201.971 L637.339,201.971 L637.339,183.851 L637.399,183.851 L649.879,201.971 L652.159,201.971 L652.159,180.551 L650.119,180.551 L650.119,198.671 L650.059,198.671 L637.579,180.551 z" fill={fillColor}/>
              <path d="M672.109,187.031 L674.149,187.031 Q673.939,185.351 673.144,184.061 Q672.349,182.771 671.164,181.886 Q669.979,181.001 668.524,180.551 Q667.069,180.101 665.539,180.101 Q663.019,180.101 661.114,181.016 Q659.209,181.931 657.949,183.476 Q656.689,185.021 656.059,187.031 Q655.429,189.041 655.429,191.261 Q655.429,193.481 656.059,195.491 Q656.689,197.501 657.949,199.031 Q659.209,200.561 661.114,201.476 Q663.019,202.391 665.539,202.391 Q667.429,202.391 668.974,201.791 Q670.519,201.191 671.659,200.081 Q672.799,198.971 673.519,197.381 Q674.239,195.791 674.449,193.811 L672.409,193.811 Q672.259,195.251 671.719,196.496 Q671.179,197.741 670.309,198.671 Q669.439,199.601 668.239,200.141 Q667.039,200.681 665.539,200.681 Q663.409,200.681 661.879,199.871 Q660.349,199.061 659.374,197.726 Q658.399,196.391 657.934,194.696 Q657.469,193.001 657.469,191.261 Q657.469,189.491 657.934,187.811 Q658.399,186.131 659.374,184.796 Q660.349,183.461 661.879,182.651 Q663.409,181.841 665.539,181.841 Q666.709,181.841 667.774,182.186 Q668.839,182.531 669.724,183.191 Q670.609,183.851 671.224,184.826 Q671.839,185.801 672.109,187.031 z" fill={fillColor}/>
              <path d="M677.569,180.551 L677.569,201.971 L692.509,201.971 L692.509,200.231 L679.609,200.231 L679.609,191.771 L691.549,191.771 L691.549,190.031 L679.609,190.031 L679.609,182.291 L692.359,182.291 L692.359,180.551 z" fill={fillColor}/>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default Logo
