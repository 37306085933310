export type PurchaseWebhook = {customer: {name: string}, ID: string}
export type User = {id: number, email: string, name: string, apiKey: string}
export type UserLoginParameters = {email: string, password: string}
export type UserCreateParameters = {
  email: string,
  name: string,
  purchaseID?: string | null,
  accessCode?: string | null,
  password: string,
  confirmationPassword: string
}
export type ResultsStoreParameters = {userID: string, answers: string[]}

const API = {
  user: {
    login: '/api/user/login',
    create: '/api/user/create',
    exists: '/api/user/exists',
    update: '/api/user/update'
  },
  purchase: '/api/purchase',
  results: {
    store: '/api/results/store',
    find: '/api/results/find' // includes :userID
  },
  generatePDF: '/api/pdf/assessment-results.pdf',
  // provides way for wkhtmltopdf to see pdf view, this is an 'api' endpoint
  // but is actually served by the frontend react app via routing.
  displayPDF: '/client-pdf-display'
}

export default API
