import React, { useState, useEffect } from 'react'
import Logo from './logo'
import { Link } from "react-router-dom"

const NavLabel = (text) => {
  <div className='text-indigo-400'>{text}</div>
}

const printState = (questionnaire, state) => {
  console.log(questionnaire)
  console.log(state)
}

const DebugMenu = ({resetOnClick, completeOnClick, questionnaire, state}) => {
  return (
    <>
      <div className="uppercase text-xs text-indigo-300">Debug Menu</div>
      <div className="text-xs mt-2 bg-indigo-300 text-indigo-800 px-2 py-1 rounded shadow select-none hover:bg-indigo-200 cursor-pointer" onClick={resetOnClick}>Reset</div>
      <div className="text-xs mt-2 bg-indigo-300 text-indigo-800 px-2 py-1 rounded shadow select-none hover:bg-indigo-200 cursor-pointer" onClick={completeOnClick}>Complete</div>
      <div className="mb-2 text-xs mt-2 bg-indigo-300 text-indigo-800 px-2 py-1 rounded shadow select-none hover:bg-indigo-200 cursor-pointer" onClick={() => (printState(questionnaire, state))}>Print State</div>
    </>
  )
}

const Nav = ({questionnaire, setAuth, user, setUser, state, setState, resetOnClick, completeOnClick}) => {
  const scenarioLabels = (questionnaire) => {
    if (questionnaire.scenarios) {
      return questionnaire.scenarios.map((m, i) => {
        var color = 'text-indigo-100'
        if (state.scenario === i+1) {
          color = 'text-green-300'
        } else if (state.scenario > i+1) {
          color = 'text-indigo-400'
        }
        return <div key={i} className={"mt-3 transition duration-500 " + color}>{m.displayName}</div>
      })
    } else {
      return <></>
    }
  }
  const introLabel = (state) => {
    if (state.stage === 'introduction') {
      return <div className="transition duration-500 text-green-300">Introduction</div>
    } else {
      return <div className="transition duration-500 text-indigo-400">Introduction</div>
    }
  }

  const resultsLabel = (state) => {
    if (state.stage === 'results') {
      return <div className="mt-3 transition duration-500 text-green-300">Results</div>
    } else {
      return <div className="mt-3 text-indigo-100">Results</div>
    }
  }

  const logOut = () => {
    setAuth(false)
    setUser({id: -1, email: 'demo@example.com', name: 'Demo User', apiKey: ''})
    setState({scenario: 0, question: 0, stage: 'introduction', answers: []})
  }

  const userIcon = <svg className="fill-current text-green-200" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z"/></svg>

  const ProfileButton = () => {
    return (
      <Link to="/" onClick={logOut} className="rounded border border-indigo-300 mt-4 mb-2 p-2 border-indigo-300 flex items-center">
        <div className="text-base">{userIcon}</div>
        <div className="inline-flex flex-col ml-3">
          <div className="text-xs">{user.name}</div>
          {/* <div className="text-sm text-indigo-200">View Profile</div> */}
          <div className="text-xs text-indigo-200">Log out</div>
        </div>
      </Link>
    )
  }

  var debug = <></>
  if (window.location.hostname === 'localhost') {
    debug = <div><DebugMenu completeOnClick={completeOnClick} resetOnClick={resetOnClick} questionnaire={questionnaire} state={state}/></div>
  }

  return (
    <nav className="nav-bg xbg-indigo-900 fixed h-screen hidden lg:inline-flex flex-col justify-between py-4 pl-8 pr-12 text-indigo-100 shadow-2xl w-56">
      <div id="nav">
        <Logo/>
        {introLabel(state)}
        {scenarioLabels(questionnaire)}
        {resultsLabel(state)}
      </div>
      <div>
        {debug}
        <ProfileButton/>
        <div className="text-indigo-400 text-xs">
          &copy; 2021 AAHP
        </div>
      </div>
    </nav>
  )
}

export default Nav
