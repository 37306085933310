import React from 'react'

const Button = ({label, onClick, className = "", disabled = false, color}) => {
  let colorClasses = "hover:bg-indigo-800 bg-indigo-600 text-indigo-100"
  if (color === 'green') {
    colorClasses = "hover:bg-green-800 bg-green-600 text-green-100"
  }

  const classes = "disabled:opacity-50 inline-block p-2 rounded transition duration-500 select-none cursor-pointer " + colorClasses + " " + className

  return (
    <button className={classes} onClick={onClick} disabled={disabled}>{label}</button>
  )
}

export default Button
