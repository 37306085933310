import React, { useState, ReactElement, Dispatch, SetStateAction } from 'react'
import FullLogo from './FullLogo'
import Button from './components/button'
import { Redirect, Link } from 'react-router-dom'
import { User, UserLoginParameters, UserCreateParameters } from '../common/api'
import Server from './server'

type Mode = 'login' | 'create'

const Login = (
  {email, password}: UserLoginParameters,
  setAuth: Dispatch<SetStateAction<boolean>>,
  setPassword: Dispatch<SetStateAction<string>>,
  setUser: Dispatch<SetStateAction<User>>
): void => {
  Server.login({email, password})
    .then(({granted, user}) => {
      console.log(granted)
      if (granted) {
        setAuth(granted)
        setUser(user)
      } else {
        alert('wrong credentials')
        setPassword('')
      }
    })
    .catch((err) => {
      console.error(err)
      alert('an error occurred trying to login, please reload the page and try again')
    })
}

const Create = (
  input: UserCreateParameters,
  setAuth: Dispatch<SetStateAction<boolean>>,
  setRedirect: Dispatch<SetStateAction<boolean>>,
  setUser: Dispatch<SetStateAction<User>>
): void => {
  console.log(input.email)

  if (input.email === '') {
    alert('email must be provided')
  } else if (input.name === '') {
    alert('name must be provided')
  } else if (input.password === '') {
    alert('password must be provided')
  } else if (input.password !== input.confirmationPassword) {
    alert('password & confirmation password do not match')
  } else if (input.purchaseID !== '' && input.accessCode !== '') {
    alert("you can't use a purchase ID & an access code to sign up, please use only one.")
  } else if (input.purchaseID === '' && input.accessCode === '') {
    alert('either a purchase ID or access code must be provided in order to sign up.')
  } else {
    Server.create(input)
      .then(r => {
        console.log(r)
        if (r.created) {
          setAuth(true)
          setUser(r.user)
          setRedirect(true)
        } else {
          console.log(r.message)
          alert(r.message)
        }
      })
      .catch(err => {
        console.log(err)
        alert('problem reaching server, please refresh and try again')
      })
  }
}

const EntryForm = ({mode, setAuth, setUser}: {mode: Mode, setAuth: Dispatch<SetStateAction<boolean>>, setUser: Dispatch<SetStateAction<User>>}): ReactElement => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [purchaseID, setPurchaseID] = useState("")
  const [accessCode, setAccessCode] = useState("")
  const [password, setPassword] = useState("")
  const [confirmationPassword, setConfirmationPassword] = useState("")
  const [redirect, setRedirect] = useState(false)

  let handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      Create({email, name, password, confirmationPassword, purchaseID, accessCode}, setAuth, setRedirect, setUser)
    }
  }
  let content = <>
    <input id="email" className="shadow rounded p-2" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} onKeyDown={(e) => handleKeyDown(e)}/> 
    <input id="name" className="shadow rounded p-2 mt-2" placeholder="Enter name" value={name} onChange={e => setName(e.target.value)} onKeyDown={(e) => handleKeyDown(e)}/> 
    <div className="w-96 text-xs italic mt-4">If you have purchased the assessment, please use the purchase ID from your confirmation email. Access Codes are only used for group purchases.</div>
    <div className="flex flex-row items-baseline mb-2">
    <input id="purchaseID" className="shadow rounded p-2 mt-2" placeholder="Enter purchase ID" value={purchaseID} onChange={e => setPurchaseID(e.target.value)} onKeyDown={handleKeyDown}/> 
      <span className="ml-2">or</span>
    <input id="accessCode" className="shadow rounded p-2 ml-2 mt-2" placeholder="Access Code" value={accessCode} onChange={e => setAccessCode(e.target.value)} onKeyDown={handleKeyDown}/> 
    </div>
    <input id="password" type="password" className="mt-3 shadow rounded p-2" placeholder="Create new password" value={password} onChange={e => setPassword(e.target.value)} onKeyDown={handleKeyDown}/> 
    <input id="confirmationPassword" type="password" className="mt-3 shadow rounded p-2" placeholder="Confirm new password" value={confirmationPassword} onChange={e => setConfirmationPassword(e.target.value)} onKeyDown={handleKeyDown}/> 
    <Button label="Create Account" onClick={() => Create({email, name, password, confirmationPassword, purchaseID, accessCode}, setAuth, setRedirect, setUser)} className="mt-4"/>
    <div className="mt-4">Already have an account? <Link className="hover:underline" to="/">Login here</Link></div>
  </>

    if (mode === 'login') {
      handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          Login({email, password}, setAuth, setPassword, setUser)
        }
      }
      content = <>
        <input className="shadow rounded p-2" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} onKeyDown={(e) => handleKeyDown(e)}/> 
        <input type="password" className="mt-3 shadow rounded p-2" placeholder="Enter password" value={password} onChange={e => setPassword(e.target.value)} onKeyDown={(e) => handleKeyDown(e)}/> 
        <Button label="Login" onClick={() => Login({email, password}, setAuth, setPassword, setUser)} className="mt-4"/>
        <Link className="hover:underline mt-2" to="/sign-up">Create Account</Link>
      </>
    }

  if (redirect) {
    return <Redirect to="/"/>
  } else {
    return (
      <div className="nav-bg h-screen flex flex-col items-center justify-between">
        <div>&nbsp;</div>
        <div className="inline-flex flex-col justify-center items-center fast-fade shadow-2xl rounded py-20 px-10 bg-indigo-100 text-indigo-900">
          <FullLogo/>
          <div className="mt-10 inline-flex flex-col">
            {content}
            </div>
          </div>
          <footer className="p-4 text-indigo-200">&copy; 2021 AAHP</footer>
        </div>
      )
    }
}

export default EntryForm
