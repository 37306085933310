export type State = {
  scenario: number,
  question: number,
  startedScenario: boolean,
  stage: string,
  answers: Answer[]
}

// UserState holds information about the user we want to use in the browser, as
// opposed to the User model from the database (that holds the password etc)
export type UserState = {name: string, email: string}

export type Answer = "a" | "b" | "c" | "d" | "e"

export type Answers = Answer[]

export type Competency =
  "ltw" |
  "edi" |
  "com" |
  "saw" |
  "twm" |
  "pdm" |
  "sfw" |
  "etm" |
  "pro"

export type CompetencyList = {
  a: Competency[]
  b: Competency[]
}

export type Question = {
  bestAnswer: number;
  competencies: CompetencyList;
  descriptionA: string;
  descriptionB: string;
  order: number;
}

export type Scenario = {
  description: string;
  displayName: string;
  name: string;
  questions: Question[];
}

export type Questionnaire = {
  logicVersion: number;
  markingMatrixA: number[][];
  markingMatrixB: number[][];
  scenarios: Scenario[];
}

export const attributes = {
  "ltw": "Leadership & Teamwork",
  "edi": "Equality Diversity & Inclusion",
  "com": "Communication",
  "saw": "Situational Awareness",
  "twm": "Time & Workload Management",
  "pdm": "Problem Solving & Decision Making",
  "sfw": "Stress, Fatigue & Wellbeing",
  "etm": "Human Error & Threat Management",
  "pro": "Professional Standards"
};

export type Percentiles = {
  ltw: number;
  edi: number;
  com: number;
  saw: number;
  twm: number;
  pdm: number;
  sfw: number;
  etm: number;
  pro: number;
}
