import React, { useState, useEffect, ReactElement, }  from 'react'
import { MarkAsPercentile, emptyResults } from './marking'
import { Chart } from './chart'
import { sampleAnswers } from '../common/debug'
import { Answers, attributes } from '../common/model'
import { Understanding, ResultDescription, Outcome } from './results'

// Sizing info:
// height: '297mm',
// width: '210mm',
//
// A4 3508 x 2480 px
// scaled down 1056 x 746
// Ratio of about 1.4145***

const displayPDF = (): ReactElement => {
  const [questionnaire, setQuestionnaire] = useState({})
  const [percentiles, setPercentiles] = useState(emptyResults)
  let name = 'Demo User'
  let answers = sampleAnswers
  const params = new URLSearchParams(window.location.search)

  if (params.has('userName')) {
    name = atob(params.get('userName') as string) 
  }

  if (params.has('answers')) {
    answers = JSON.parse(atob(params.get('answers') as string)) as Answers
  }

  useEffect(() => {

    if (Object.keys(questionnaire).length === 0) {
      fetch('questionnaire.json')
        .then(response => response.json())
        .then(data => {
          console.log('questionnaire data loaded');
          setQuestionnaire(data)
          console.log(MarkAsPercentile(data, answers))
          setPercentiles(MarkAsPercentile(data, answers))
        });
    }

  })

  const Cover = ({name}: {name: string}): ReactElement => {
    return (
      <div style={{
        height: '1130px',
        background: 'url(/images/pdf-cover.png)',
        backgroundSize: 'cover'
      }}>
        <div className="pl-20 pt-40">
          <div className="text-white text-xl">Performance Indicator Assessment</div>
          <div className="text-green-400">Personal Report for {name}</div>
        </div>
      </div>
    )
  }

  const Contents = (): ReactElement => {
    return (
      <div style={{
        height: '1130px',
        background: 'url(/images/pdf-contents-bg.png)',
        backgroundSize: 'cover'
      }}>
        <div className="pl-20 pt-20">
          <div className="text-white text-2xl">Contents</div>
          <div className="pt-10 text-green-400 text-xl">Understanding your results</div>
          <div className="pt-5 text-white text-xl">- Why are competencies so important?</div>
          <div className="text-white text-xl">- What are Competencies?</div>
          <div className="text-white text-xl">- Where Do You Stand?</div>

          <div className="pt-10 text-green-400 text-xl">Your results</div>
          <div className="pt-5 text-white text-xl">- Leadership & Teamwork</div>
          <div className="text-white text-xl">- Equality, Diversity, & Inclusion</div>
          <div className="text-white text-xl">- Communication</div>
          <div className="text-white text-xl">- Situational Awareness</div>
          <div className="text-white text-xl">- Time & Workload Management</div>
          <div className="text-white text-xl">- Problem Solving & Decision-Making</div>
          <div className="text-white text-xl">- Stress, Fatigue, and Wellbeing Management</div>
          <div className="text-white text-xl">- Human Error & Threat Management</div>
          <div className="text-white text-xl">- Professional Standards & Self-Awareness</div>

          <div className="pt-10 text-green-400 text-xl">The Outcome</div>
          <div className="pt-5 text-white text-xl">- Become the conscious competent</div>
          <div className="text-white text-xl">- Optimise your performance</div>
          <div className="text-white text-xl">- Realise your potential</div>
        </div>
      </div>
    )
  }

  const pdfNewCleanPage = {
    height: '1130px',
    background: 'url(/images/pdf-clean-page.png)',
    backgroundSize: 'cover',
    pageBreakBefore: 'always'
  }

  return (
    <div style={{fontFamily: "'Open Sans', sans-serif"}}>
      <Cover name={name}/>
      <Contents/>
      <Understanding pdf={true}/>

      <div style={pdfNewCleanPage} className="relative">
        <div className="pt-10 pl-16 leading-6 text-blue-900"><b>Your Competency Wheel</b></div>
        <div className="absolute font-semibold text-xs bg-blue-800 text-white px-2 py-1 z-20 rounded shadow" style={{top: "160px", left: "435px"}}>Leadership & Teamwork</div>
        <div className="absolute font-semibold text-xs bg-blue-800 text-white px-2 py-1 z-20 rounded shadow" style={{top: "310px", left: "570px"}}>Equality, Diversity & Inclusion</div>
        <div className="absolute font-semibold text-xs bg-blue-800 text-white px-2 py-1 z-20 rounded shadow" style={{top: "490px", left: "670px"}}>Communication</div>
        <div className="absolute font-semibold text-xs bg-blue-800 text-white px-2 py-1 z-20 rounded shadow" style={{top: "690px", left: "550px"}}>Situational Awareness</div>
        <div className="absolute font-semibold text-xs bg-blue-800 text-white px-2 py-1 z-20 rounded shadow" style={{top: "760px", left: "320px"}}>Time & Workload Management</div>
        <div className="absolute font-semibold text-xs bg-blue-800 text-white px-2 py-1 z-20 rounded shadow" style={{top: "670px", left: "100px"}}>Problem Solving & Decision Making</div>
        <div className="absolute font-semibold text-xs bg-blue-800 text-white px-2 py-1 z-20 rounded shadow" style={{top: "500px", left: "30px"}}>Stress, Fatigue & Wellbeing</div>
        <div className="absolute font-semibold text-xs bg-blue-800 text-white px-2 py-1 z-20 rounded shadow" style={{top: "320px", left: "50px"}}>Human Error & Threat Management</div>
        <div className="absolute font-semibold text-xs bg-blue-800 text-white px-2 py-1 z-20 rounded shadow" style={{top: "160px", left: "220px"}}>Professional Standards</div>

        <div className="mt-20 z-10">
          <Chart percentiles={percentiles} />
        </div>
      </div>

      <ResultDescription shorthand="ltw" title={attributes.ltw} score={percentiles.ltw} pdf={true}/>
      <ResultDescription shorthand="edi" title={attributes.edi} score={percentiles.edi} pdf={true}/>
      <ResultDescription shorthand="com" title={attributes.com} score={percentiles.com} pdf={true}/>
      <ResultDescription shorthand="saw" title={attributes.saw} score={percentiles.saw} pdf={true}/>
      <ResultDescription shorthand="twm" title={attributes.twm} score={percentiles.twm} pdf={true}/>
      <ResultDescription shorthand="pdm" title={attributes.pdm} score={percentiles.pdm} pdf={true}/>
      <ResultDescription shorthand="sfw" title={attributes.sfw} score={percentiles.sfw} pdf={true}/>
      <ResultDescription shorthand="etm" title={attributes.etm} score={percentiles.etm} pdf={true}/>
      <ResultDescription shorthand="pro" title={attributes.pro} score={percentiles.pro} pdf={true}/>

      <Outcome pdf={true}/>
    </div>
  )
}

export default displayPDF
