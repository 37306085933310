// import { Chart, PolarAreaController, LinearScale, RadialLinearScale, ArcElement} from 'chart.js'
// import ApexCharts from 'apexcharts'
import React, { ReactElement }  from 'react'
import ApexCharts from 'react-apexcharts'

// colorByThreshold returns a value to colour the report chart
const colorByThreshold = (score: number) => {
  if (score >= 85) {
    return '#00E800'
  } else if (score >= 70) {
    return '#86FF00'
  } else if (score >= 55) {
    return '#FFFF00'
  } else if (score >= 40) {
    return '#FFB600'
  } else if (score >= 25) {
    return '#FF1700'
  } else {
    return '#D20000'
  }
}

export const Chart = ({percentiles}): ReactElement => {
  const ltwColor = colorByThreshold(percentiles['ltw'])
  const ediColor = colorByThreshold(percentiles['edi'])
  const comColor = colorByThreshold(percentiles['com'])
  const sawColor = colorByThreshold(percentiles['saw'])
  const twmColor = colorByThreshold(percentiles['twm'])
  const pdmColor = colorByThreshold(percentiles['pdm'])
  const sfwColor = colorByThreshold(percentiles['sfw'])
  const etmColor = colorByThreshold(percentiles['etm'])
  const proColor = colorByThreshold(percentiles['pro'])

  const series = [
    percentiles['ltw'],
    percentiles['edi'],
    percentiles['com'],
    percentiles['saw'],
    percentiles['twm'],
    percentiles['pdm'],
    percentiles['sfw'],
    percentiles['etm'],
    percentiles['pro']
  ]

  const options = {
    labels: ["Leadership & Teamwork", "Equality Diversity & Inclusion", "Communication", "Situational Awareness", "Time & Workload Management", "Problem Solving & Decision Making", "Stress, Fatigue & Wellbeing", "Human Error & Threat Management", "Professional Standards"],
    legend: {
      show: false,
      labels: {
        colors: [ ltwColor, ediColor, comColor, sawColor, twmColor, pdmColor, sfwColor, etmColor, proColor ]
      }
    },
    chart: {
      foreColor: '#000',
      animations: {
        enabled: false,
        dynamicAnimation: { enabled: false }
      } // TODO temporarily display animations for PDF
    },
    colors: [ ltwColor, ediColor, comColor, sawColor, twmColor, pdmColor, sfwColor, etmColor, proColor ],
    stroke: {
      width: 0
    },
    yaxis: {
      show: false,
    },
    plotOptions: {
      polarArea: {
        rings: {
          strokeWidth: 1,
          strokeColor: '#374151'
        },
        spokes: {
          strokeWidth: 1,
          connectorColors: '#374151'
        }
      }
    },
    fill: {
      opacity: 0.8,
        colors: [ ltwColor, ediColor, comColor, sawColor, twmColor, pdmColor, sfwColor, etmColor, proColor ]
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          type: 'polarArea',
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
};

  return (
    <ApexCharts options={options} series={series} type="polarArea" />
  )
}

export default Chart
